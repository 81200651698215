﻿import i18n from "../../shared/i18n/i18n";
import Requester from "../../shared/request";
import Gateway from "../../shared/app_settings";
import Toasts from "../../shared/toasts";
import Auth from "../../shared/auth";
import m from "mithril";
import Component_Account_SSO from "./component_account_sso";

let acct, loading, tab = "sso";

function loadAccount() {
    loading = true;
    Requester.get(Gateway.admin + "account/settings")
        .then(function (result) {
            acct = result;
        }).catch(function (error) {
        Toasts.add("error", i18n.t("error"), i18n.t(error.response.properties.errorCode));
    }).finally(function () {
        loading = false;
    });
}

const Page_Account = {
    oninit: function (vnode) {
        loadAccount();
    },
    view: function () {
        return [
            m(".content-box", [
                m(".toolbar", {class: loading ? "disable-buttons" : ""}, [
                    m(".toolbar-section with-menu", [
                        m("h1", i18n.t("account")),
                        m(".toolbar-menu", [
                            Auth.validateRights(["account_single_sign_on_configuration_view"], m("div", {
                                class: tab === "sso" ? "active" : "", onclick: function () {
                                    tab = "sso";
                                }
                            }, i18n.t("single_sign_on")), true)
                        ])
                    ])
                ])
            ]),
            !loading ? [
                acct ? [
                    tab === "sso" ? m(Component_Account_SSO, {acct: acct})
                        : ""
                ] : m(".text-gray content-box", i18n.t( "unable_to_load_account"))
            ] : m(".text-gray content-box", [i18n.t("loading"), m(".loading-dots")])
        ];
    }
};

export default Page_Account;
