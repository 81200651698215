import i18n from "../../shared/i18n/i18n";

import m from 'mithril';
import Auth from '../../shared/auth';
import SessionController from '../../shared/session';
import version from "../../../package.json";
import RouterService from "../../shared/services/router.service";

let currentPage = "";

let Sidebar = {
    setMenu: function (el, dontSelect, dontReset) {
        if (!dontReset) {
            Sidebar.resetMenu();

            if (!dontSelect)
                el.classList.add("selected");

            while (el && !el.classList.contains("menu-top")) {
                el = el.parentNode;
                if (el.tagName === "LI") {
                    el.classList.add("open");
                }
            }
        } else {
            if (!dontSelect)
                el.classList.toggle("selected");

            while (el && !el.classList.contains("menu-top")) {
                el = el.parentNode;
                if (el.tagName === "LI") {
                    el.classList.toggle("open");
                }
            }
        }
    },
    resetMenu: function () {
        let selected = document.querySelectorAll(".selected");
        for (let i = 0; i < selected.length; i++) {
            selected[i].classList.remove("selected");
        }

        let opened = document.querySelectorAll(".accordion.open");
        for (let i = 0; i < opened.length; i++) {
            opened[i].classList.remove("open");
        }
    },
    toggleTheme: function () {
        let theme = "light";

        if (document.documentElement.getAttribute("theme") === "light")
            theme = "dark";

        document.documentElement.setAttribute("theme", theme);
        localStorage.setItem("theme", theme);
    },
    oninit: function (vnode) {
    },
    view: function () {
        return [
            m("#sidebar-wrapper", [
                m("#sidebar", [
                    m(".sidebar-section", [
                        m(".sidebar-header", [
                            m("h3", i18n.t("account"))
                        ]),
                        m("ul", [
                            Auth.validateRights(["org_view"], m("li", [
                                m(m.route.Link, {
                                    href: RouterService.getOrganisationsUrl(),
                                    onclick: function () {
                                        Sidebar.setMenu(this);
                                    },
                                    class: "menu-top" + (currentPage === "organisations" ? " selected" : "")
                                }, i18n.t("organisations"))
                            ])),
                            Auth.validateRights(["users_view"],
                                m("li", [
                                    m(m.route.Link, {
                                        href: RouterService.getAccountUsersUrl(), onclick: function () {
                                            Sidebar.setMenu(this);
                                        }, class: "menu-top" + (currentPage === "users" ? " selected" : "")
                                    }, i18n.t("users"))
                                ])
                            ),
                            Auth.validateRights(["account_settings_view"], m("li", [
                                m(m.route.Link, {
                                    href: RouterService.getAccountSettingsUrl(), onclick: function () {
                                        Sidebar.setMenu(this);
                                    }, class: "menu-top" + (currentPage === "settings" ? " selected" : "")
                                }, i18n.t("settings"))
                            ]))
                        ])
                    ]),
                    m(".sidebar-section", [
                        m("ul", [
                            SessionController.session && SessionController.session.identity ? [
                                m("li.accordion menu-top", [
                                    m("button", {
                                        onclick: function () {
                                            Sidebar.setMenu(this, false, true);
                                        }
                                    }, SessionController.session.identity.name),
                                    m("ul", [
                                        m("li", [
                                            m(m.route.Link, {
                                                class: "button",
                                                href: RouterService.getAccountProfileUrl()
                                            }, i18n.t("profile"))
                                        ]),
                                        m("li", [
                                            m("button", {
                                                onclick: function () {
                                                    event.preventDefault();
                                                    SessionController.logout();
                                                }
                                            }, i18n.t("logout"))
                                        ]),
                                        m("li", [
                                            m(".button", {onclick: Sidebar.toggleTheme}, [
                                                document.documentElement.getAttribute("theme") === "light" ? i18n.t("dark_mode") : i18n.t("light_mode"),
                                                m("#theme-toggle.ml-1", {style: "display: inline-block; vertical-align: middle;"})
                                            ])
                                        ]),
                                        m("small", `v${version.version}`)
                                    ])
                                ])
                            ] : ""
                        ])
                    ])
                ])
            ])
        ];
    }
};

export default Sidebar;
