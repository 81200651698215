﻿import m from "mithril";
import Requester from "../../shared/request";
import Gateway from "../../shared/app_settings";
import Toasts from "../../shared/toasts";
import Auth from "../../shared/auth";
import Component_Organisation_Details from "./component_organisation_details";
import Component_Organisation_Settings from "./component_organisation_settings";
import i18n from "../../shared/i18n/i18n";
import RouterService from "../../shared/services/router.service";
import SessionController from "../../shared/session";

let loading, org, tab = "settings", currentPage;

function loadOrg(id) {
    loading = true;
    Requester.get(Gateway.admin + "account/organisations/" + id)
        .then(function (result) {
            org = result;
        }).catch(function (error) {
        Toasts.add("error", i18n.t("error"), i18n.t(error.response.properties.errorCode));
    }).finally(function () {
        loading = false;
    });
}

function accessOrg() {
    loading = true;
    Requester.get(Gateway.identity + "req-tr?target=" + org.id)
        .then(function (result) {
            SessionController.clearSession();
            SessionController.getSession().then(function () {
                window.location = RouterService.getOrganisationTransitionUrl(org.hostname, result, RouterService.getAdminUrl());    window.location = RouterService.getOrganisationTransitionUrl(org.hostname, result, RouterService.getAdminUrl());
            });
        })
        .catch(function (error) {
            Toasts.add("error", i18n.t("error"), i18n.t(error.response.properties.errorCode));
        })
        .then(function () {
            loading = false;
        });
}

const Page_Organisation = {
    oninit: function (vnode) {
        loadOrg(vnode.attrs.id);
        currentPage = "organisations";
    },
    view: function () {
        return [
            m(".content-box", [
                m(".toolbar", {class: loading ? "disable-buttons" : ""}, [
                    m(".toolbar-section with-menu", [
                        m("h1", i18n.t("organisation")),
                        m(".toolbar-menu", [
                            m("div", {
                                class: tab === "details" ? "active" : "", onclick: function () {
                                    tab = "details";
                                }
                            }, i18n.t("details")),
                            m("div", {
                                class: tab === "settings" ? "active" : "", onclick: function () {
                                    tab = "settings";
                                }
                            }, i18n.t("settings"))
                        ])
                    ]),
                    m(".toolbar-section", [
                        Auth.validateInternalAccess(m("button.with-icon btn-cta", {onclick: accessOrg}, [m("i.icon-scale"), i18n.t("visit_organisation")]))
                    ])
                ])
            ]),
            !loading ? [
                org ? [
                    tab === "details" ? m(Component_Organisation_Details, {org: org})
                        : tab === "settings" ? m(Component_Organisation_Settings, {org: org})
                            : ""
                ] : m(".text-gray content-box", i18n.t("unable_to_load_organisation"))
            ] : m(".text-gray content-box", [i18n.t("loading"), m(".loading-dots")])
        ];
    }
};

export default Page_Organisation;
