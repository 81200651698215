﻿import m from "mithril";
import Requester from "../../shared/request";
import Gateway from "../../shared/app_settings";
import Toasts from "../../shared/toasts";
import i18n from "../../shared/i18n/i18n";
import SessionController from "../../shared/session";
import Loading from "../../shared/loading";
import RouterService from "../../shared/services/router.service";

function verifyToken(token) {
    Requester.post(Gateway.identity + "auth-tr?token=" + token + "&env=true")
        .then(function (result) {
            const redirectRoute = m.route.param("redirect");
            SessionController.setSession(result);
            if (redirectRoute) {
                RouterService.navigateTo(redirectRoute);
            } else {
                RouterService.navigateToPublicCourses();
            }
        })
        .catch(function (error) {
            RouterService.navigateToPublicLogin();
            Toasts.add("error", i18n.t("error"), i18n.t(error.response.properties.errorCode));
        });
}


const PublicPage_Transition = {
    oninit: function (vnode) {
        if (!m.route.param("token")) {
            RouterService.navigateToPublicCourses();
            Toasts.add("error", i18n.t("cant_verify"), "");
        }

        verifyToken(m.route.param("token"));
    },
    view: function () {
        return [
            m(Loading, {class: "loading-inline"})
        ];
    }
};
export default PublicPage_Transition;
