﻿import m from "mithril";
import Requester from "../../../shared/request";
import Gateway from "../../../shared/app_settings";
import Toasts from "../../../shared/toasts";
import i18n from "../../../shared/i18n/i18n";
import ModalManager from "../../../shared/modal";
import Modal_Confirm_Delete from "../../../shared/components/modal_confirm_delete";
import Auth from "../../../shared/auth";
import UnitSettingsUtilities from "../unit_settings_utilities";
import FormUtilities from '../../../shared/utilities/form_utilities';
import RouterService from "../../../shared/services/router.service";

let saving, uploading, deleting, unit;

function saveUnitDetails() {
    if (!FormUtilities.validateFields(".details input")) {
        return;
    }

    saving = true;
    Requester.post(Gateway.admin + "units/certificate/" + unit.id, {
        id: unit.id,
        name: unit.name,
        description: unit.description
    })
        .then(function (result) {
            Toasts.add("success", i18n.t("saved_changes"));
        })
        .catch(function (error) {
            Toasts.add("error", i18n.t("error"), i18n.t(error.response.properties.errorCode));
        })
        .then(function () {
            saving = false;
        });
}

function confirmDeleteUnit() {
    ModalManager.open(i18n.t("delete_unit"), [], Modal_Confirm_Delete, {callback: deleteUnit});
}

function deleteUnit() {
    deleting = true;
    Requester.delete(Gateway.admin + "units/certificate/" + unit.id)
        .then(function () {
            RouterService.navigateToUnits();
        })
        .catch(function (error) {
            Toasts.add("error", i18n.t("error"), i18n.t(error.response.properties.errorCode));
        })
        .finally(function () {
            deleting = false;
        });
}

const Component_Unit_Certificate_Settings = {

    oninit: function (vnode) {
        unit = vnode.attrs.unit;
    },
    view: function () {
        return [
            m(".content-box", [
                m("h3", i18n.t("details")),
                m(".form .details", {class: !Auth.validateRights(["unit_certificate_edit"]) ? "disabled" : ""}, [
                    m(".form-section required", [
                        m(".form-label", i18n.t("name")),
                        m("input[type=text]", {
                            oninput: function () {
                                unit.name = this.value;
                            }, value: unit.name
                        })
                    ]),
                    m(".form-section", [
                        m(".form-label", i18n.t("description")),
                        m("textarea", {
                            oninput: function () {
                                unit.description = this.value;
                            }, value: unit.description
                        })
                    ]),
                    m(".form-buttons", [
                        !saving && !uploading ? m("button", {onclick: saveUnitDetails}, i18n.t("save")) : m("button.btn-text no-click", [i18n.t("saving"), m(".loading-dots")])
                    ])
                ])
            ]),
            m(".content-box", [
                m(".form", {class: !Auth.validateRights(["unit_certificate_edit"]) ? "disabled" : ""}, [
                    m(".flex-row justify-between", [
                        m("h3.mb-0", i18n.t("active")),
                        m(".btn btn-toggle", {
                            onclick: function () {
                                UnitSettingsUtilities.toggleActiveStatus([unit.id], !unit.isActive, saving);
                                unit.isActive = !unit.isActive;
                            },
                            class: unit.isActive ? "active" : ""
                        })
                    ])
                ])
            ]),
            Auth.validateRights(["unit_certificate_delete"], [
                m(".content-box", [
                    !deleting ? m("button.btn-error", {onclick: confirmDeleteUnit}, i18n.t("delete_unit")) : m("button.btn-text no-click text-error", [i18n.t("deleting"), m(".loading-dots")])
                ])
            ])
        ];
    }
};
export default Component_Unit_Certificate_Settings;
