﻿let FormUtilities = {
	validateFields: function (fields) {
		let errors = false;
		Array.prototype.forEach.call(document.querySelectorAll(fields), function (el, i) {
			el.parentNode.classList.remove("has-error");
			if (el.closest(".required") &&
				((!el.classList.contains("checkbox-group") && !el.classList.contains("radio-group")) ? (
					(el.value === null || el.value.length === 0 || el.value.length === "" || (el.type === "email" && !FormUtilities.validateEmail(el.value)) || (el.type === "password" && el.value.length < 6))
					|| (el.type === "email" && el.value.length > 0 && !FormUtilities.validateEmail(el.value))
				) : (el.querySelectorAll("input:checked").length === 0))) {
				el.parentNode.classList.add("has-error");
				errors = true;
			}
		});
		return !errors;
	},

	validateEmail: function (email, allowEmpty = false) {
		if (allowEmpty && (email === null || email.trim() === '')) {
			return true;
		}

		let regex = /^([a-zA-Z0-9_.+'-])+\@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$/;
		return regex.test(email);
	},

	validateUsername: function (username, allowEmpty = false) {
		if (allowEmpty && (username === null || username.trim() === '')) {
			return true;
		}
		return window.ENV.USERNAME_PREFIX === null || window.ENV.USERNAME_PREFIX === '' ? true :
			username.toLowerCase().startsWith(window.ENV.USERNAME_PREFIX.toLowerCase());
	}
}

export default FormUtilities;
