﻿import m from "mithril";
import FormUtilities from "../../../shared/utilities/form_utilities";
import Requester from "../../../shared/request";
import Gateway from "../../../shared/app_settings";
import i18n from "../../../shared/i18n/i18n";
import Toasts from "../../../shared/toasts";
import ModalManager from "../../../shared/modal";
import Modal_Confirm_Delete from "../../../shared/components/modal_confirm_delete";
import Auth from "../../../shared/auth";
import UnitSettingsUtilities from "../unit_settings_utilities";
import RouterService from "../../../shared/services/router.service";

let saving, uploading, deleting, unit, templates;

function saveUnitDetails() {
    if (FormUtilities.validateFields(".form input")) {
        saving = true;
        Requester.post(Gateway.admin + "units/action/" + unit.id, {
            id: unit.id,
            name: unit.name,
            description: unit.description
        })
            .then(function () {
                Toasts.add("success", i18n.t("saved_changes"), "");
            }).catch(function (error) {
            Toasts.add("error", i18n.t("error"), i18n.t(error.response.properties.errorCode));
        })
            .finally(function () {
                saving = false;
            });
    }
}

function saveUnitSettings() {
    saving = true;
    Requester.post(Gateway.admin + "units/action/send-email/" + unit.id, {
        emailTemplateId: unit.emailTemplateId,
        toAddresses: unit.toAddresses
    })
        .then(function (result) {
            saving = false;
            Toasts.add("success", i18n.t("saved_changes"), "");
        }).catch(function (error) {
        saving = false;
        Toasts.add("error", i18n.t("error"), i18n.t(error.response.properties.errorCode));
    });
}

function getTemplates() {
    Requester.get(Gateway.admin + "emails/templates")
        .then(function (result) {
            templates = result.entities;
        }).catch(function (error) {
        Toasts.add("error", i18n.t("error"), i18n.t(error.response.properties.errorCode));
    })
}

function getCurrentTemplateName() {
    if (unit.emailTemplateId && templates)
        for (let i = 0; i < templates.length; i++)
            if (templates[i].properties.id === unit.emailTemplateId)
                return templates[i].properties.name;

    return i18n.t("none");
}

function confirmDeleteUnit() {
    ModalManager.open(i18n.t("delete_unit"), [], Modal_Confirm_Delete, {callback: deleteUnit});
}

function deleteUnit() {
    deleting = true;
    Requester.delete(Gateway.admin + "units/action/" + unit.id)
        .then(function () {
            RouterService.navigateToUnits();
        })
        .catch(function (error) {
            Toasts.add("error", i18n.t("error"), i18n.t(error.response.properties.errorCode));
        })
        .finally(function () {
            deleting = false;
        });
}

function addEmail() {
    let emailField = document.getElementById("add-email-field");
    if (emailField.value && FormUtilities.validateEmail(emailField.value) && unit.toAddresses.indexOf(emailField.value) === -1) {
        unit.toAddresses.push(emailField.value);
        emailField.value = "";
        emailField.classList.remove("has-error");
    } else {
        emailField.classList.add("has-error");
    }
}

function removeEmail(email) {
    let index = unit.toAddresses.indexOf(email);
    if (index > -1)
        unit.toAddresses.splice(index, 1);
}

const Component_Unit_Action_SendEmail_Settings = {
    oninit: function (vnode) {
        unit = vnode.attrs.unit;
        if (!unit.toAddresses)
            unit.toAddresses = [];

        getTemplates();
    },
    view: function () {
        return [
            m(".content-box", [
                m("h3", i18n.t("details")),
                m(".form", {class: !Auth.validateRights(["unit_action_edit"]) ? "disabled" : ""}, [
                    m(".form-section required", [
                        m(".form-label", i18n.t("name")),
                        m("input[type=text]", {
                            oninput: function () {
                                unit.name = this.value;
                            }, value: unit.name
                        })
                    ]),
                    m(".form-section", [
                        m(".form-label", i18n.t("description")),
                        m("textarea", {
                            oninput: function () {
                                unit.description = this.value;
                            }, value: unit.description
                        })
                    ]),
                    m(".form-buttons", [
                        !saving && !uploading ? m("button", {onclick: saveUnitDetails}, i18n.t("save")) : m("button.btn-text no-click", [i18n.t("saving"), m(".loading-dots")])
                    ])
                ])
            ]),
            m(".content-box", [
                m("h3", i18n.t("settings")),
                m(".form", {class: !Auth.validateRights(["unit_action_edit"]) ? "disabled" : ""}, [
                    m(".form-section", [
                        m(".form-label", i18n.t("email_template")),
                        m(".dropdown",
                            m("button.with-icon pl-2", [
                                getCurrentTemplateName(),
                                m("i.icon-back rotate-270 ml-2")
                            ]),
                            m(".dropdown-list", [
                                m("div", {
                                    onclick: function () {
                                        unit.emailTemplateId = null;
                                    }, class: !unit.emailTemplateId ? "text-bold" : ""
                                }, i18n.t("none")),
                                templates && templates.length > 0 ? templates.map(function (template) {
                                    return [
                                        m("div", {
                                            onclick: function () {
                                                unit.emailTemplateId = template.properties.id;
                                            }, class: unit.emailTemplateId === template.properties.id ? "text-bold" : ""
                                        }, template.properties.name)
                                    ];
                                }) : ""
                            ])
                        )
                    ]),
                    m(".form-section", [
                        m(".form-label", i18n.t("recipient_email_addresses")),
                        m(".form-section with-button", [
                            m("input[type=email]#add-email-field[placeholder=example@email.com]", {
                                onkeyup: function (e) {
                                    if (e.keyCode === 13) {
                                        addEmail();
                                    }
                                }
                            }),
                            m("button.active", {onclick: addEmail}, "+")
                        ])
                    ]),
                    unit.toAddresses ? unit.toAddresses.map(function (email) {
                        return [
                            m(".form-section", [
                                m("button.btn-text", {
                                    onclick: function () {
                                        removeEmail(email);
                                    }
                                }, "x"),
                                email
                            ])
                        ];
                    }) : "",
                    m(".form-buttons", [
                        !saving && !uploading ? m("button", {onclick: saveUnitSettings}, i18n.t("save_settings")) : m("button.btn-text no-click", [i18n.t("saving"), m(".loading-dots")])
                    ])
                ])
            ]),
            m(".content-box", [
                m(".form", {class: !Auth.validateRights(["unit_action_edit"]) ? "disabled" : ""}, [
                    m(".flex-row justify-between", [
                        m("h3.mb-0", i18n.t("active")),
                        m(".btn btn-toggle", {
                            onclick: function () {
                                UnitSettingsUtilities.toggleActiveStatus([unit.id], !unit.isActive, saving);
                                unit.isActive = !unit.isActive;
                            },
                            class: unit.isActive ? "active" : ""
                        })
                    ])
                ])
            ]),
            Auth.validateRights(["unit_action_delete"], [
                m(".content-box", [
                    !deleting ? m("button.btn-error", {onclick: confirmDeleteUnit}, i18n.t("delete_unit")) : m("button.btn-text no-click text-error", [i18n.t("deleting"), m(".loading-dots")])
                ])
            ])
        ];
    }
};
export default Component_Unit_Action_SendEmail_Settings;
