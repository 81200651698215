﻿import m from "mithril";
import Requester from "../../shared/request";
import Gateway from "../../shared/app_settings";
import Toasts from "../../shared/toasts";
import i18n from "../../shared/i18n/i18n";
import ModalManager from "../../shared/modal";
import Modal_Confirm_Delete from "../../shared/components/modal_confirm_delete";
import {UnitTypes} from "../values";
import DateUtilities from "../../shared/utilities/date_utilities";
import Auth from "../../shared/auth";
import UnitUtilities from "../../shared/utilities/unit_utilities";
import Modal_Scorm_Activity from "./modal_scorm_activity";
import Modal_Scorm_Progress_Preview from "./modal_scorm_preview";
import Modal_Manage_Session_Enrolment from "./modal_manage_session_enrolment";
import Modal_Approval_Reject from "./modal_approval_reject";
import RouterService from "../../shared/services/router.service";
import SessionController from "../../shared/session";
import {getCourseEnrolmentStatus} from "../../shared/utilities/courseEnrolmentStatus_utilities";
import Modal_Form from "../../public/course/modal_form";

let enrolmentId, loading, enr, checklist, selected = [];
let saving = false;
let unitApproverMap = {};   //UnitId -> boolean, true if current user configured approver of unit

function reloadEnrolment() {
    Requester.get(Gateway.admin + "courses/enrolments/" + enr.properties.id)
        .then(function (result) {
            enr = result
            loading = false;
        });
}

function loadEnrolment(id) {
    loading = true;
    selected = []

    Requester.get(Gateway.admin + "courses/enrolments/" + id)
        .then(function (result) {
            enr = result;
            loadChecklist(enr.properties.courseId);

            enr.properties.unitEnrolments
                .filter(unitEnrolment => unitEnrolment.unitType === 'Approval')
                .map(unit =>
                    loadApprovalUnit(unit.unitId)
                        .then(function (result) {
                            // There will be a slight delay in loading any UI elements relying on this data
                            unitApproverMap[unit.unitId] = result.properties.approverIds.includes(SessionController.session.identity.id);
                        })
                );
        }).catch(function (error) {
            Toasts.add("error", i18n.t("error"), i18n.t(error.response.properties.errorCode));
        }).finally(function () {
            loading = false;
        });
}

function loadChecklist(courseId) {
    Requester.get(Gateway.admin + "courses/" + courseId + "/checklist")
        .then(function (result) {
            checklist = result.properties.checklist;
        })
        .catch(function (error) {
            Toasts.add("error", i18n.t("error"), i18n.t(error.response.properties.errorCode));
        });
}

function toggleEnrolmentChecklistItem(itemId) {
    loading = true;
    Requester.post(Gateway.admin + "courses/enrolments/" + enr.properties.id + "/checklist/" + itemId)
        .then(function () {
            const itemIndex = enr.properties.checklist.indexOf(itemId);
            if (itemIndex === -1)
                enr.properties.checklist.push(itemId);
            else
                enr.properties.checklist.splice(itemIndex, 1);
        }).catch(function (error) {
        Toasts.add("error", i18n.t("error"), i18n.t(error.response.properties.errorCode));
    }).finally(function () {
        loading = false;
    });
}

function changeEnrolmentResult(status) {
    changeResult("courses", status, [enr.properties.id]);
}

function changeUnitResult(status) {
    if (selected) {
        loading = true;
        Requester.post(Gateway.admin + "units/enrolments/result", {ids: selected, status: status, isCancelled: false, courseEnrolmentIds: [enr.properties.id]})
            .then(function (result) {
                reloadEnrolment();
                Toasts.add("success", i18n.t("saved_changes"), "");
            }).catch(function (error) {
            Toasts.add("error", i18n.t("error"), i18n.t(error.response.properties.errorCode));
        }).finally(function () {
            loading = false;
        });
    }
}

function changeResult(type, status, ids) {
    loading = true;
    Requester.post(Gateway.admin + type + "/enrolments/result", {ids: ids, status: status})
        .then(function (result) {
            reloadEnrolment();
            Toasts.add("success", i18n.t("saved_changes"), "");
        }).catch(function (error) {
        Toasts.add("error", i18n.t("error"), i18n.t(error.response.properties.errorCode));
    }).finally(function () {
        loading = false;
    });
}

function archiveEnrolment() {
    ModalManager.open(i18n.t("archive_enrolment"), [], Modal_Confirm_Delete, {
        callback: function () {
            submitArchiveEnrolment(true);
        },
        text: "Archived enrolments will remain in the database, but won't be visible to users or in the enrolment list.",
        btnText: "Archive"
    });
}

function submitArchiveEnrolment(isArchived) {
    loading = true;
    Requester.post(Gateway.admin + "courses/enrolments/archive", {ids: [enr.properties.id], isArchived: isArchived, skipUnarchiveLatestActiveEnrolment: true})
        .then(function () {
            reloadEnrolment();
        }).catch(function (error) {
        Toasts.add("error", i18n.t("cant_save"), error.response.status.detail);
        loading = false;
    });
}

function openScormActivity(unitEnr) {
    ModalManager.open(i18n.t("interactive_unit_activity"), ["modal-wide"], Modal_Scorm_Activity, {unitEnrolment: unitEnr});
}

function openScormPreview(unitEnr) {
    ModalManager.open(i18n.t("Unit_Progress_read_only"), ["modal-huge", "modal-fill-height"], Modal_Scorm_Progress_Preview, {unitEnrolment: unitEnr});
}

function manageSessionEnrolment(unit) {
    ModalManager.open(i18n.t("manage_session_enrolment"), [], Modal_Manage_Session_Enrolment, {
        unit: unit,
        callback: reloadEnrolment,
        cancelCallback: confirmCancelSessionEnrolment
    });
}

function viewFormResponse(readOnly, unit, enrolmentId) {
    let classes = !readOnly ? ["no-close"] : [];
    ModalManager.open(unit.unitName, classes, Modal_Form, {
        enrolmentId: enrolmentId,
        readOnly: readOnly,
        loadFromAdmin: true,
        unitEnrolmentId: unit.id,
        callback: reloadEnrolment
    });
}

function confirmCancelSessionEnrolment(unit) {
    if (!Auth.validateRights(["enrolments_edit"])) {
        return;
    }
    ModalManager.open(unit.properties.isCancelled ? "Restore Session Enrolment" : "Cancel Session Enrolment", [], Modal_Confirm_Delete, {
        text: unit.properties.isCancelled ? "Restore the user's attendance in this session?" : "Cancel the user's attendance in this session?",
        btnText: unit.properties.isCancelled ? "Restore Session Enrolment" : "Cancel Session Enrolment",
        callback: function () {
            cancelSessionEnrolment(unit);
        }
    });
}

function cancelSessionEnrolment(unit) {
    loading = true;
    Requester.post(Gateway.admin + "units/event/enrolments/cancel", {ids: [unit.properties.id], isCancelled: !unit.properties.isCancelled})
        .then(function () {
            reloadEnrolment();
        }).catch(function (error) {
        Toasts.add("error", i18n.t("error"), i18n.t(error.response.properties.errorCode));
    }).finally(function () {
        loading = false;
    });
}

function toggleAllSelected() {
    if (selected.length > 0)
        selected = [];
    else {
        if (enr.entities) {
            for (let i = 0; i < enr.entities.length; i++) {
                selected.push(enr.entities[i].properties.id);
            }
        }
    }
}

function toggleSelected(id) {
    const index = selected.indexOf(id);
    if (index === -1)
        selected.push(id);
    else
        selected.splice(index, 1);
}

function updateApprovalUnitEnrolment(unit, status, message, user, unitReturned) {
    saving = true;
    Requester.post(Gateway.admin + "units/approval/enrolments/" + unit.properties.id, {
            id: unit.properties.id,
            unitId: unit.properties.unitId,
            isApproved: status,
            approverFeedback: message,
            approverName: user,
            unitReturned: unitReturned
        })
        .then(function () {
            reloadEnrolment();
        }).catch(function (error) {
            Toasts.add("error", i18n.t("error"), i18n.t(error.response.properties.errorCode));
        }).finally(function () {
            saving = false;
        })
}

function rejectApprovalUnit(unit, user) {
    ModalManager.open(i18n.t("return_approval_unit"), [], Modal_Approval_Reject,
        {
            unitEnrolment: unit,
            user: user,
            callback: updateApprovalUnitEnrolment}
    );
}
function getUnitLabel(unit) {
    if (unit.unitType === UnitTypes.event && unit.isCancelled) {
        return m(".label-circle label-error", i18n.t("cancelled"));
    } else if (unit.unitType === UnitTypes.approval) {
        if (unit.isCompleted && unit.isPassed)
            return m(".label-circle label-success", i18n.t("approved"));
        else if (unit.resubmitted)
            return m(".label-circle label-warning", i18n.t("submitted"));
        else if (unit.unitReturned && !unit.isPassed)
            return m(".label-circle label-warning", i18n.t("returned"));
        else if (unit.submitted)
            return m(".label-circle label-warning", i18n.t("submitted"));
        else
            return m(".label-circle", i18n.t("not_submitted"))
    } else {
        if (unit.isCompleted && unit.isPassed)
            return m(".label-circle label-success", i18n.t("passed"));
        else if (unit.isCompleted && !unit.isPassed)
            return m(".label-circle label-error", i18n.t("failed"));
        else if (unit.isStarted)
            return m(".label-circle label-warning", i18n.t("in_progress"));
        else
            return m(".label-circle", i18n.t("not_started"));
    }
}

function loadApprovalUnit(id) {
    loading = true;

    return Requester.get(Gateway.admin + "units/" + id)
        .catch(function (error) {
            Toasts.add("error", i18n.t("error"), i18n.t(error.response.properties.errorCode));
        }).finally(function () {
            loading = false;
        });
}

function downloadUploadUnitEnrolmentFile(unit) {
    loading = true;
    Requester.get(Gateway.admin + "units/enrolments/" + unit.properties.id)
        .then(function (result) {
            if (!result.properties.filePath)
                Toasts.add("error", i18n.t("file_not_found"));
            else
                window.open(result.properties.filePath, '_self');
        }).catch(function (error) {
            Toasts.add("error", i18n.t("error"), i18n.t(error.response.properties.errorCode));
        }).finally(function () {
            loading = false;
        });
}

function showButtonsIfUserAuthorisedApprover(unitEnrolment) {
    let buttonOutput = [
        !unitEnrolment.properties.isCompleted || !unitEnrolment.properties.isPassed ? m("button.btn-text", {
            onclick: function () {
                updateApprovalUnitEnrolment(unitEnrolment, true, "Your request has been approved. If your course has additional modules to complete, please move forward with these.",
                    SessionController.session.identity.name);
            }
        }, i18n.t("approve")) : "",
        !unitEnrolment.properties.isCompleted || !unitEnrolment.properties.isPassed ? m("button.btn-text", {
            onclick: function () {
                rejectApprovalUnit(unitEnrolment, SessionController.session.identity.name);
            }
        }, i18n.t("return")) : "",
    ]

    if(unitApproverMap[unitEnrolment.properties.unitId]) return buttonOutput;
    if(Auth.validateRights(["unit_approval_action"])) return buttonOutput;

    return "";
}


const Page_Enrolment = {
    oninit: function (vnode) {
        enrolmentId = vnode.attrs.id;
        loadEnrolment(vnode.attrs.id);
    },
    view: function () {
        return [
            m(".content-box", [
                m(".toolbar", {class: loading ? "disable-buttons" : ""}, [
                    m(".toolbar-section", [
                        m("h1", i18n.t("enrolment"))
                    ]),
                    enr ? [
                        m(".toolbar-section", [
                            Auth.validateRights(["enrolments_edit"],
                                m(".dropdown",
                                    m("button.with-icon pl-2", [
                                        m("i.icon-edit"),
                                        i18n.t("change_result"),
                                        m("i.icon-back rotate-270 ml-2")
                                    ]),
                                    m(".dropdown-list", [
                                        !enr.properties.isArchived ? [
                                            m("div", {
                                                onclick: function () {
                                                    changeEnrolmentResult();
                                                }
                                            }, i18n.t("not_started")),
                                            m("div", {
                                                onclick: function () {
                                                    changeEnrolmentResult("passed");
                                                }
                                            }, i18n.t("passed")),
                                            m("div", {
                                                onclick: function () {
                                                    changeEnrolmentResult("failed");
                                                }
                                            }, i18n.t("failed")),
                                            Auth.validateRights([Auth.rights.enrolments_delete], m("div", {onclick: archiveEnrolment}, i18n.t("archived")))
                                            
                                        ] : m("div", {
                                            onclick: function () {
                                                submitArchiveEnrolment(false);
                                            }
                                        }, i18n.t("un_archive"))
                                    ])
                                )
                            )
                        ])
                    ] : ""
                ])
            ]),
            m(".content-box", [
                m(".flex-auto", [
                    m(".details-list", [
                        m(".form-section", [
                            enr && enr.properties.isArchived ? m(".label label-warning", i18n.t("enrolment_archived")) : ""
                        ]),
                        m(".form-section", [
                            m(".text-label", i18n.t("course")),
                            enr ? (Auth.validateRights(["courses_view"]) ? m(m.route.Link, {
                                href: RouterService.getCourseUrl(enr.properties.courseId),
                                class: "btn-open"
                            }, enr.properties.courseName) : enr.properties.courseName) : m(".loading-dots")
                        ]),
                        m(".form-section", [
                            m(".text-label", i18n.t("user")),
                            enr ? (Auth.validateRights(["users_view"]) ? m(m.route.Link, {
                                href: RouterService.getAdminUserUrl(enr.properties.userId),
                                class: "btn-open"
                            }, enr.properties.firstName ? enr.properties.firstName + " " + enr.properties.lastName : i18n.t("invited_user")) : enr.properties.firstName ? enr.properties.firstName + " " + enr.properties.lastName : i18n.t("invited_user")) : m(".loading-dots")
                        ]),
                        m(".form-section", [
                            m(".text-label", i18n.t("result")),
                            getCourseEnrolmentStatus(enr, true, loading)
                        ]),
                        m(".form-section", [
                            m(".text-label", i18n.t("created")),
                            enr ? DateUtilities.getFormattedDateFromUTC(enr.properties.creationDate) || m(".text-gray", "-") : m(".loading-dots")
                        ]),
                        m(".form-section", [
                            m(".text-label", i18n.t("completed")),
                            enr ? DateUtilities.getFormattedDateFromUTC(enr.properties.completionDate) || m(".text-gray", "-") : m(".loading-dots")
                        ]),
                        m(".form-section", [
                            m(".text-label", i18n.t("last_launched")),
                            enr ? DateUtilities.getFormattedDateFromUTC(enr.properties.lastActiveDate) || m(".text-gray", "-") : m(".loading-dots")
                        ])
                    ]),
                    enr && checklist && checklist.length > 0 ? [
                        m(".form-section", [
                            m(".text-label", i18n.t("checklist")),
                            checklist.map(function (item) {
                                return [
                                    m("", {
                                        onclick: Auth.validateRights(["enrolments_edit"], function () {
                                            toggleEnrolmentChecklistItem(item.id);
                                        }), class: Auth.validateRights(["enrolments_edit"], "c-hand")
                                    }, [
                                        m("i.mr-1", {class: enr.properties.checklist.indexOf(item.id) !== -1 ? "icon-checkbox-checked" : "icon-checkbox-empty"}), item.name
                                    ])
                                ];
                            })
                        ])
                    ] : ""
                ])
            ]),
            m(".content-box", [
                m(".toolbar", {class: loading ? "disable-buttons" : ""}, [
                    m(".toolbar-section", [
                        m("h3", i18n.t("unit_enrolments"))
                    ]),
                    enr && !enr.properties.isArchived ? [m(".toolbar-section", [
                        Auth.validateRights(["enrolments_edit"],
                            m(".dropdown", {class: selected.length === 0 ? "disabled" : ""},
                                m("button.with-icon pl-2", [
                                    m("i.icon-edit"),
                                    i18n.t("change_result"),
                                    m("i.icon-back rotate-270 ml-2")
                                ]),
                                m(".dropdown-list", [
                                    m("div", {
                                        onclick: function () {
                                            changeUnitResult();
                                        }
                                    }, i18n.t("not_started")),
                                    m("div", {
                                        onclick: function () {
                                            changeUnitResult("passed");
                                        }
                                    }, i18n.t("passed")),
                                    m("div", {
                                        onclick: function () {
                                            changeUnitResult("failed");
                                        }
                                    }, i18n.t("failed"))
                                ])
                            )
                        )
                    ])
                    ] : ""
                ]),
                !loading ? [
                    enr && enr.entities.length > 0 ? [
                        m(".table-wrapper", [
                            m("table.full-width", [
                                m("thead",
                                    m("tr", [
                                        !enr.properties.isArchived ? m("th", m(".toolbar-checkbox", {onclick: toggleAllSelected}, m("i", {class: selected.length > 0 ? "icon-checkbox-checked" : "icon-checkbox-empty"}))) : "",
                                        m("th", i18n.t("unit")),
                                        m("th", i18n.t("type")),
                                        m("th", i18n.t("result")),
                                        m("th", i18n.t("actions"))
                                    ])
                                ),
                                m("tbody", enr.entities.map(function (unit) {
                                    return [
                                        m("tr", [
                                            !enr.properties.isArchived ? m("td.list-checkbox c-hand pl-1 pr-1", {
                                                onclick: function () {
                                                    toggleSelected(unit.properties.id);
                                                }, style: "width: 18px;"
                                            }, m("i", {class: selected.indexOf(unit.properties.id) !== -1 ? "icon-checkbox-checked" : "icon-checkbox-empty"})) : "",
                                            m("td.text-bolder", unit.properties.unitName + (unit.properties.unitType === UnitTypes.event && unit.properties.sessionName && unit.properties.sessionName !== unit.properties.unitName ? (" - " + unit.properties.sessionName) : "")),
                                            m("td.with-icon", [
                                                m("i", {class: UnitUtilities.getUnitTypeIcon(unit.properties.unitType)}),
                                                unit.properties.unitType
                                            ]),
                                            m("td", getUnitLabel(unit.properties)),
                                            m("td", [
                                                unit.properties.unitType === UnitTypes.scorm ? [
                                                    m("button.btn-text", {
                                                        onclick: function () {
                                                            openScormActivity(unit.properties);
                                                        }
                                                    }, i18n.t("view_activity")),
                                                    m("button.btn-text", {
                                                        onclick: function () {
                                                            openScormPreview(unit.properties);
                                                        }
                                                    }, i18n.t("launch"))
                                                ] : "",
                                                unit.properties.unitType === UnitTypes.event ?
                                                    m("", {class: !Auth.validateRights(["unit_event_view"]) ? "disabled" : ""}, [
                                                        !enr.properties.isArchived ? m("button.btn-text", {
                                                            onclick: function () {
                                                                manageSessionEnrolment(unit);
                                                            }
                                                        }, i18n.t("session_details")) : ""
                                                    ]) : "",

                                                unit.properties.unitType === UnitTypes.approval ? showButtonsIfUserAuthorisedApprover(unit) : "",
                                                unit.properties.unitType === UnitTypes.upload ? [
                                                    unit.properties.filePath ? m("button.btn-text", {
                                                        onclick: function () {
                                                            downloadUploadUnitEnrolmentFile(unit);
                                                        }
                                                    }, i18n.t("download_file")) : ""
                                                ] : "",
                                                unit.properties.unitType === UnitTypes.form ?
                                                    m("", {class: !Auth.validateRights(["enrolments_form_unit_response_view"]) ? "disabled" : ""}, [
                                                        unit.properties.isCompleted ? m("button.btn-text", {
                                                            onclick: function () {
                                                                viewFormResponse(true, unit.properties, enrolmentId);
                                                            }
                                                        }, i18n.t("view_response")) : ""
                                                    ]) : "",
                                            ])
                                        ])
                                    ];
                                }))
                            ])
                        ])
                    ] : m(".text-gray padding", i18n.t("no_units"))
                ] : m(".text-gray padding", [i18n.t("loading"), m(".loading-dots")])
            ])
        ];
    }
};

export default Page_Enrolment;
