﻿import m from "mithril";
import Toasts from "../../shared/toasts";
import SessionController from "../../shared/session";
import AuthLogo from "../../shared/components/component_logo";
import Loading from "../../shared/loading";
import i18n from "../../shared/i18n/i18n";
import Language_Selector from "../../shared/components/component_language_selector";
import RouterService from "../../shared/services/router.service";
import Contact_Support_team from "./contact_support_team";
import Log_In_Button from "./log_in_button";

let loading = false;
let showLangDropDown;

let session = () => SessionController.session;
let sessionEnv = () => SessionController.session.environment;
let sessionEnvReady = () => SessionController.session && SessionController.session.environment;

const PublicPage_Login = {
    oninit: function () {
        loading = true;
        showLangDropDown = false;
        if (session() && session().identity) {
            RouterService.navigateOnLogin();
            Toasts.add("success", i18n.t("already_logged_in"), "");
        } else {
            SessionController.setCurrentSession();
        }

        if (sessionEnvReady() && sessionEnv().useExternalLogin && sessionEnv().externalLoginUrl) {
            window.location = sessionEnv().externalLoginUrl;
        }

        loading = false;
    },
    view: function () {
        return [
            m("div#login-form-container.relative", [
                !loading ? m("div.splash-container", [
                    m(AuthLogo),
                    sessionEnvReady() && sessionEnv().about ? [
                        m("div.company-tagline", sessionEnv().about),
                    ] : "",
                    m('h3#sign-in-text.avetta-blue-heading', i18n.t("sign-in-to-continue")),
                    m('.pt-2.pb-2.fill-width.lang-container', [m("strong", i18n.t("select_language")),
                        m(Language_Selector, {dropdown_class: `pb-2 pt-1 fill-width dropdown-no-hover ${showLangDropDown ? 'show-lang-dropdown' : ''}`,
                        on_click: function () {
                            showLangDropDown = !showLangDropDown
                        }})
                    ]),
                    m(Log_In_Button),
                    sessionEnvReady() && sessionEnv().disableSignup ? "" : [
                        m("h5.or-line", m("span", i18n.t("or"))),
                        m(
                            m.route.Link, {
                                href: RouterService.getPublicLoginAsVisitorUrl(),
                                selector: "a.btn.fill-width.full-btn.login-as-visitor.centre-content"
                            },
                            m("span", i18n.t("sign-up-as-a-visitor"))
                        )
                    ],
                    m(Contact_Support_team)
                ]) : m(Loading, {class: "loading-inline"})
            ])
        ];
    }
};
export default PublicPage_Login;
