﻿import m from "mithril";
import ModalManager from '../modal';
import i18n from "../i18n/i18n";

let modalId;

function close() {
    ModalManager.close(modalId);
}

const Modal_Confirm_Delete = {
    view: function (vnode) {
        return [
            m(".form-section", [
                vnode.attrs.text || "Are you sure you want to delete?"
            ]),
            m(".form-buttons", [
                m("button.btn-error", {
                    onclick: function () {
                        vnode.attrs.callback();
                        close();
                    }
                }, vnode.attrs.btnText || "Delete"),
                m("button", {onclick: close}, i18n.t("close"))
            ])
        ];
    },
    setModalId: function(id) {
        modalId = id;
    }
};

export default Modal_Confirm_Delete;
