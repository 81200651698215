﻿import m from "mithril";
import Requester from "../../shared/request";
import Gateway from "../../shared/app_settings";
import Toasts from "../../shared/toasts";
import OrgFileService from "../../shared/storage/org_file_service";
import i18n from "../../shared/i18n/i18n";
import RouterService from "../../shared/services/router.service";
import SessionController from "../../shared/session";

let loading = false;
let orgs = null;

function loadOrganisations() {
    loading = true;
    Requester.get(Gateway.identity + "organisations")
        .then(function (result) {
            orgs = result;
        }).catch(function (error) {
        Toasts.add("error", i18n.t("error"), i18n.t(error.response.properties.errorCode));
    }).finally(function () {
        loading = false;
    });
}


function accessOrg(org) {
    loading = true;
    Requester.get(Gateway.identity + "req-tr?target=" + org.id)
        .then(function (result) {
            SessionController.clearSession();
            SessionController.getSession().then(function () {
                window.location = RouterService.getOrganisationTransitionUrl(org.hostname, result, RouterService.getPublicCoursesUrl());
            });
        })
        .catch(function (error) {
            Toasts.add("error", i18n.t("error"), i18n.t(error.response.properties.errorCode));
        })
        .then(function () {
            loading = false;
        });
}

const PublicComponent_Organisations = {
    oninit: function (vnode) {
        loadOrganisations();
    },
    view: function () {
        return [
            !loading ? [
                orgs ? [
                    orgs.length > 0 ?
                        m(".table-wrapper table-responsive", [
                            m("table.full-width", [
                                m("thead",
                                    m("tr", [
                                        m("th.pl-2", i18n.t("organisation")),
                                        m("th.pl-1", i18n.t("role")),
                                        m("th.pl-1", i18n.t("actions"))
                                    ])
                                ),
                                m("tbody", orgs.map(function (org) {
                                    return [
                                        m("tr", [
                                            m("td.pl-2 text-bolder",
                                                m("span", [
                                                    org.logoName ? m("img.mr-1", {
                                                        style: "height: 10px;",
                                                        oncreate: function (e) {
                                                            e.dom.setAttribute("src", OrgFileService.getLogoPath(org.id, org.logoName));
                                                        }
                                                    }) : "",
                                                    org.organisationName
                                                ])
                                            ),
                                            m("td.pl-1", i18n.t(org.role)),
                                            m("td.pl-1", [
                                                org.hostname !== window.location.hostname ? m("a.btn-text c-hand", {
                                                    onclick: function () {
                                                        accessOrg(org);
                                                    }
                                                }, i18n.t("log_in")) : ""
                                            ]),
                                        ])
                                    ];
                                }))
                            ])
                        ])
                        : m(".text-gray padding", i18n.t("no_organisations"))
                ] : m(".text-gray padding", i18n.t("unable_to_load_organisations"))
            ] : m(".text-gray padding", [i18n.t("loading"), m(".loading-dots")])
        ];
    }
};
export default PublicComponent_Organisations;
