﻿import m from "mithril";
import Requester from "../../../shared/request";
import Gateway from "../../../shared/app_settings";
import i18n from "../../../shared/i18n/i18n";
import Toasts from "../../../shared/toasts";
import ModalManager from "../../../shared/modal";
import Modal_Confirm_Delete from "../../../shared/components/modal_confirm_delete";
import Auth from "../../../shared/auth";
import FormUtilities from "../../../shared/utilities/form_utilities";
import UnitSettingsUtilities from "../unit_settings_utilities";
import RouterService from "../../../shared/services/router.service";

let saving, loading, deleting, unit;

function saveUnit() {
    if (FormUtilities.validateFields(".form event-settings input")) {

        if (unit.endTime < unit.startTime)
            Toasts.add("error", i18n.t("cant_save"), i18n.t("start_before_end"));

        saving = true;
        Requester.post(Gateway.admin + "units/event/" + unit.id, unit)
            .then(function (result) {
                Toasts.add("success", i18n.t("saved_changes"), "");
            }).catch(function (error) {
            Toasts.add("error", i18n.t("error"), i18n.t(error.response.properties.errorCode));
        }).finally(function () {
            saving = false;
        });
    }
}

function toggleAllowChangeSession() {
    loading = true;
    Requester.post(Gateway.admin + "units/event/" + unit.id + "/toggle-change")
        .then(function () {
            unit.allowChangeSession = !unit.allowChangeSession;
            Toasts.add("success", i18n.t("saved_changes"), "");
        })
        .catch(function (error) {
            Toasts.add("error", i18n.t("error"), i18n.t(error.response.properties.errorCode));
        })
        .finally(function () {
            loading = false;
        });
}

function confirmDeleteUnit() {
    ModalManager.open(i18n.t("delete_unit"), [], Modal_Confirm_Delete, {callback: deleteUnit});
}

function deleteUnit() {
    deleting = true;
    Requester.delete(Gateway.admin + "units/event/" + unit.id)
        .then(function () {
            RouterService.navigateToUnits();
            Toasts.add("success", i18n.t("saved_changes"), "");
        })
        .catch(function (error) {
            Toasts.add("error", i18n.t("error"), i18n.t(error.response.properties.errorCode));
        })
        .finally(function () {
            deleting = false;
        });
}

const Component_Unit_Event_Settings = {
    oninit: function (vnode) {
        unit = vnode.attrs.unit;
    },
    view: function () {
        return [
            m(".content-box", [
                m("h3", i18n.t("details")),
                m(".form event-settings", {class: !Auth.validateRights(["unit_event_edit"]) ? "disabled" : ""}, [
                    m(".form-section required", [
                        m(".form-label", i18n.t("name")),
                        m("input[type=text]", {
                            oninput: function () {
                                unit.name = this.value;
                            }, value: unit.name
                        })
                    ]),
                    m(".form-section", [
                        m(".form-label", i18n.t("description")),
                        m("textarea", {
                            oninput: function () {
                                unit.description = this.value;
                            }, value: unit.description
                        })
                    ]),
                    m(".mt-1 mb-1 text-bold", i18n.t("default_sessions_setting")),
                    m(".form-section", [
                        m(".form-label", i18n.t("info")),
                        m("textarea", {
                            oninput: function () {
                                unit.information = this.value;
                            }, value: unit.information
                        })
                    ]),
                    m(".form-section", {class: unit.recurring ? "required" : ""}, [
                        m(".form-label", i18n.t("location")),
                        m("input[type=text]", {
                            oninput: function () {
                                unit.location = this.value;
                            }, value: unit.location
                        })
                    ]),
                    Auth.validateRights(["unit_event_edit"], [
                        m(".form-buttons", [
                            !saving ? m("button", {onclick: saveUnit}, i18n.t("save")) : m("button.btn-text no-click", [i18n.t("saving"), m(".loading-dots")])
                        ])
                    ])
                ])
            ]),
            m(".content-box", [
                m("h3", i18n.t("settings")),
                m(".form", {class: !Auth.validateRights(["unit_event_edit"]) ? "disabled" : ""}, [
                    m(".form-section toggle-section", [
                        m(".text-bolder", i18n.t("users_change_their_session")),
                        m(".btn btn-toggle", {
                            onclick: toggleAllowChangeSession,
                            class: unit.allowChangeSession ? "active" : ""
                        })
                    ])
                ])
            ]),
            m(".content-box", [
                m(".form", {class: !Auth.validateRights(["unit_event_edit"]) ? "disabled" : ""}, [
                    m(".flex-row justify-between", [
                        m("h3.mb-0", i18n.t("active")),
                        m(".btn btn-toggle", {
                            onclick: function () {
                                UnitSettingsUtilities.toggleActiveStatus([unit.id], !unit.isActive, saving);
                                unit.isActive = !unit.isActive;
                            },
                            class: unit.isActive ? "active" : ""
                        })
                    ])
                ])
            ]),
            Auth.validateRights(["unit_event_delete"], [
                m(".content-box", [
                    !deleting ? m("button.btn-error", {onclick: confirmDeleteUnit}, i18n.t("delete_unit")) : m("button.btn-text no-click text-error", [i18n.t("deleting"), m(".loading-dots")])
                ])
            ])
        ];
    }
};
export default Component_Unit_Event_Settings;
