﻿import m from "mithril";
import ModalManager from "../../shared/modal";
import Modal_Unit_Scorm_New from "./scorm/modal_unit_scorm_new";
import Modal_Unit_Form_New from "./form/modal_unit_form_new";
import Modal_Unit_Event_New from "./event/modal_unit_event_new";
import Modal_Unit_Certificate_New from "./certificate/modal_unit_certificate_new";
import Modal_Unit_Document_New from "./document/modal_unit_document_new";
import Modal_Unit_Action_New from "./action/modal_unit_action_new";
import Modal_Unit_Approval_New from "./approval/modal_unit_approval_new";
import Modal_Unit_Download_New from "./download/modal_unit_download_new";
import Modal_Unit_Upload_New from "./upload/modal_unit_upload_new";
import UnitUtilities from "../../shared/utilities/unit_utilities";
import Auth from "../../shared/auth";
import i18n from "../../shared/i18n/i18n";

let modalId;
function newInteractiveUnit() {
    ModalManager.open(i18n.t("create_interactive_unit"), [], Modal_Unit_Scorm_New);
}

function newFormUnit() {
    ModalManager.open(i18n.t("create_form_unit"), [], Modal_Unit_Form_New);
}

function newEventUnit() {
    ModalManager.open(i18n.t("create_face_to_face_unit"), [], Modal_Unit_Event_New);
}

function newCertificateUnit() {
    ModalManager.open(i18n.t("create_certificate_unit"), [], Modal_Unit_Certificate_New);
}

function newDocumentUnit() {
    ModalManager.open(i18n.t("create_document_unit"), [], Modal_Unit_Document_New);
}

function newActionUnit() {
    ModalManager.open(i18n.t("create_action_unit"), [], Modal_Unit_Action_New);
}

function newApprovalUnit() {
    ModalManager.open(i18n.t("create_approval_unit"), [], Modal_Unit_Approval_New);
}

function newDownloadUnit() {
    ModalManager.open(i18n.t("create_download_unit"), [], Modal_Unit_Download_New);
}

function newUploadUnit() {
    ModalManager.open(i18n.t("create_upload_unit"), [], Modal_Unit_Upload_New);
}

function getButtonClass(claim) {
    return Auth.validateRights([claim])
        ? ""
        : "disabled";
}

let launchUnit = (newUnitFunction) => {
    newUnitFunction();
    ModalManager.close(modalId);
}

const Modal_Unit_New = {
    view: function () {
        return [
            m("button.with-icon btn-big", {class: getButtonClass("unit_scorm_create"), onclick: () => launchUnit(newInteractiveUnit)}, [m("i", {class: UnitUtilities.getUnitTypeIcon(UnitUtilities.unitTypes.scorm)}), UnitUtilities.getUnitTypeName(UnitUtilities.unitTypes.scorm)]),
            m("button.with-icon btn-big", {class: getButtonClass("unit_form_create"), onclick: () => launchUnit(newFormUnit)}, [m("i", {class: UnitUtilities.getUnitTypeIcon(UnitUtilities.unitTypes.form)}), UnitUtilities.getUnitTypeName(UnitUtilities.unitTypes.form)]),
            m("button.with-icon btn-big", {class: getButtonClass("unit_event_create"), onclick: () => launchUnit(newEventUnit)}, [m("i", {class: UnitUtilities.getUnitTypeIcon(UnitUtilities.unitTypes.event)}), UnitUtilities.getUnitTypeName(UnitUtilities.unitTypes.event)]),
            m("button.with-icon btn-big", {class: getButtonClass("unit_certificate_create"), onclick: () => launchUnit(newCertificateUnit)}, [m("i", {class: UnitUtilities.getUnitTypeIcon(UnitUtilities.unitTypes.certificate)}),UnitUtilities.getUnitTypeName(UnitUtilities.unitTypes.certificate)]),
            m("button.with-icon btn-big", {class: getButtonClass("unit_document_create"), onclick: () => launchUnit(newDocumentUnit)}, [m("i", {class: UnitUtilities.getUnitTypeIcon(UnitUtilities.unitTypes.document)}),UnitUtilities.getUnitTypeName(UnitUtilities.unitTypes.document)]),
            m("button.with-icon btn-big", {class: getButtonClass("unit_action_create"), onclick: () => launchUnit(newActionUnit)}, [m("i", {class: UnitUtilities.getUnitTypeIcon(UnitUtilities.unitTypes.action)}), UnitUtilities.getUnitTypeName(UnitUtilities.unitTypes.action)]),
            m("button.with-icon btn-big", {class: getButtonClass("unit_approval_create"), onclick: () => launchUnit(newApprovalUnit)}, [m("i", {class: UnitUtilities.getUnitTypeIcon(UnitUtilities.unitTypes.approval)}), UnitUtilities.getUnitTypeName(UnitUtilities.unitTypes.approval)]),
            m("button.with-icon btn-big", {class: getButtonClass("unit_download_create"), onclick: () => launchUnit(newDownloadUnit)}, [m("i", {class: UnitUtilities.getUnitTypeIcon(UnitUtilities.unitTypes.download)}),UnitUtilities.getUnitTypeName(UnitUtilities.unitTypes.download)]),
            m("button.with-icon btn-big", {class: getButtonClass("unit_upload_create"), onclick: () => launchUnit(newUploadUnit)}, [m("i", {class: UnitUtilities.getUnitTypeIcon(UnitUtilities.unitTypes.upload)}), UnitUtilities.getUnitTypeName(UnitUtilities.unitTypes.upload)])
        ];
    },
    setModalId: function(id) {
        modalId = id;
    }
};
export default Modal_Unit_New;
