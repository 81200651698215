﻿import m from 'mithril';
import { UnitTypes } from '../../admin/values';
import UnitUtilities from '../../shared/utilities/unit_utilities';
import Component_Unit_Actions_Scorm from './component_unit_actions_scorm';
import Component_Unit_Actions_Form from './component_unit_actions_form';
import Component_Unit_Actions_Event from './component_unit_actions_event';
import Component_Unit_Actions_Document from './component_unit_actions_document';
import Component_Unit_Actions_Certificate from './component_unit_actions_certificate';
import Component_Unit_Actions_Approval from './component_unit_actions_approval';
import Component_Unit_Actions_Download from './component_unit_actions_download';
import Component_Unit_Actions_Upload from './component_unit_actions_upload';
import i18n from '../../shared/i18n/i18n';
import {getUnitStatus} from "../../shared/utilities/courseEnrolmentStatus_utilities";

const Component_Unit_List_Item = {
    view: function (vnode) {
        let unit = vnode.attrs.unit;
        let course = vnode.attrs.course;
        let unitNumber = vnode.attrs.unitNumber;
        return unit ?
            m('.SecondaryTheme tile',
                m('.padding', [
                    m('.flex-row mr-2', [
                            getUnitStatus(unit),
                        m('div', [
                            m('.unitId', { name: unit.id }),
                            m('.tile__title', unit.name),
                            m('.tile__subtitle', UnitUtilities.getUnitTypeName(unit.type)),
                        ]),
                    ]),
                    m('.btn-padding', unit ? [
                        unit.type === UnitTypes.scorm ? m(Component_Unit_Actions_Scorm, {
                                unit: unit,
                                unitNumber: unitNumber,
                                course: course,
                                reloadCallback: vnode.attrs.reloadCallback
                            })
                            : unit.type === UnitTypes.form ? m(Component_Unit_Actions_Form, {
                                    unit: unit,
                                    unitNumber: unitNumber,
                                    course: course,
                                    reloadCallback: vnode.attrs.reloadCallback
                                })
                                : unit.type === UnitTypes.event ? m(Component_Unit_Actions_Event, {
                                        unit: unit,
                                        unitNumber: unitNumber,
                                        course: course,
                                        reloadCallback: vnode.attrs.reloadCallback,
                                    })
                                    : unit.type === UnitTypes.doc ? m(Component_Unit_Actions_Document, {
                                            unit: unit,
                                            unitNumber: unitNumber,
                                            course: course,
                                            reloadCallback: vnode.attrs.reloadCallback
                                        })
                                        : unit.type === UnitTypes.cert ? m(Component_Unit_Actions_Certificate, {
                                                unit: unit,
                                                unitNumber: unitNumber,
                                                course: course,
                                                reloadCallback: vnode.attrs.reloadCallback
                                            })
                                            : unit.type === UnitTypes.approval ? m(Component_Unit_Actions_Approval, {
                                                    unit: unit,
                                                    unitNumber: unitNumber,
                                                    course: course,
                                                    reloadCallback: vnode.attrs.reloadCallback
                                                })
                                                : unit.type === UnitTypes.download ? m(Component_Unit_Actions_Download, {
                                                        unit: unit,
                                                        unitNumber: unitNumber,
                                                        course: course,
                                                        reloadCallback: vnode.attrs.reloadCallback
                                                    })
                                                    : unit.type === UnitTypes.upload ? m(Component_Unit_Actions_Upload, {
                                                            unit: unit,
                                                            unitNumber: unitNumber,
                                                            course: course,
                                                            reloadCallback: vnode.attrs.reloadCallback
                                                        })
                                                        : [m('div', i18n.t('invalid_unit_type')),]
                    ] : '')
                ]),
            ) : '';
    }
};
export default Component_Unit_List_Item;
