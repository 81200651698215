﻿import m from "mithril";
import Requester from "../../shared/request";
import Gateway from "../../shared/app_settings";
import Toasts from "../../shared/toasts";
import i18n from "../../shared/i18n/i18n";

let uploading, reloadCallback, fileInput;

function uploadFile(unit, course ) {
    let formData = new FormData();
    if (fileInput.files[0] !== null) {
        let file = fileInput.files[0];
        formData.append("file", file, file.name);

        uploading = true;

        Requester.upload(Gateway.public + "units/upload/" + unit.enrolment.id + "?enrolmentId=" + course.enrolment.id, formData)
            .then(function () {
                Toasts.add("success", i18n.t("saved_changes"), "");
                reloadCallback();
            })
            .catch(function (error) {
                Toasts.add("error", i18n.t("error"), i18n.t(error.response.properties.errorCode));
            })
            .finally(function () {
                uploading = false;
            });
    } else {
        Toasts.add("error", i18n.t("cant_upload"), error);
        uploading = false;
    }
}

const Component_Unit_Actions_Upload = {
    view: function (vnode) {
        let unit = vnode.attrs.unit;
        let course = vnode.attrs.course;
        reloadCallback = vnode.attrs.reloadCallback;
        return unit?.enrolment?.allowAccess ?
            !uploading ? [
                m("button", {
                    onclick: function () {
                        fileInput.click();
                    }, class: unit.enrolment.isCompleted ? "" : "btn-cta"
                }, !unit.enrolment.isCompleted ? i18n.t("upload_file") : i18n.t("reupload_file")),
                m("input[type=file][hidden][class=upload-input]", {
                    oninput: function (){uploadFile(unit, course)}, oncreate: function (e) {
                        fileInput = e.dom;
                    }
                })
            ] : m(".loading-bar mt-1", m(".loading-width"))
            : "";
    }
};
export default Component_Unit_Actions_Upload;
