﻿import m from "mithril";
import Toasts from "../../shared/toasts";
import i18n from "../../shared/i18n/i18n";
import SessionController from "../../shared/session";
import AuthLogo from "../../shared/components/component_logo";
import Loading from "../../shared/loading";
import RouterService from "../../shared/services/router.service";

let loading = false;
const PublicPage_Password_Forgot_Sent = {
    oninit: function () {
        loading = true;

        if (SessionController.session && SessionController.session.identity && !SessionController.sso()) {
            RouterService.navigateOnLogin();
            Toasts.add("success", i18n.t("already_logged_in"), "");
        }

        if (SessionController.session && SessionController.session.environment && SessionController.session.environment.useExternalLogin && SessionController.session.environment.externalLoginUrl)
            window.location = SessionController.session.environment.externalLoginUrl;

        loading = false;
    },
    view: function () {
        return [
            m("div", [
                !loading ? [
                    m(AuthLogo),
                    m(".form-header", i18n.t("forgot_password")),
                    m("div", [
                        m(".form-section", [
                            i18n.t("password_reset_sent"),
                            m("br"),
                            i18n.t("can_close_window")
                        ]),
                        m(".form-buttons", [
                            m(m.route.Link, {
                                href: RouterService.getPublicLoginUrl(),
                                class: "btn-text flex-float-left"
                            }, i18n.t("back_to_login"))
                        ])
                    ])
                ] : m(Loading, {class: "loading-inline"})
            ])
        ];
    }
};
export default PublicPage_Password_Forgot_Sent;
