let Gateway;
if (window.location.hostname.includes('localhost')) {
    Gateway = {
        admin: "/api/v1/",
        identity: "/gw/id/",
        public: "/gw/",
        // admin: "http://localhost:30021/api/v1/",
        // identity: "http://localhost:30023/gw/id/",
        // public: "http://localhost:30023/gw/",
    };
} else {
    Gateway = {
        admin: "https://" + window.location.hostname + "/api/v1/",
        identity: "https://" + window.location.hostname + "/gw/id/",
        public: "https://" + window.location.hostname + "/gw/"
    };
}

export default Gateway;
