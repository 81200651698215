﻿import m from "mithril";
import { CheckCircle } from '@mithril-icons/font-awesome/solid'
import i18n from "../../shared/i18n/i18n";
import RouterService from "../../shared/services/router.service";
import Contact_Support_team from "../auth/contact_support_team";

let email;

const Component_Visitor_Registration_Success = {
    oninit: function (vnode) {
        email = vnode.attrs.email;
    },
    view: function () {
        return [
            m(".relative", [
                m("div.splash-container", [
                    m(CheckCircle, { width: 50, height: 50, fill: '#00ba62', class: 'form-section' }),
                    m('h2', i18n.t("activation_email_sent")),
                    m('p', i18n.t("we_sent_an_email_to")),
                    m('h2', email),
                    m('p.text-center',
                        i18n.t("activation_email_details") + " ",
                        m(
                            m.route.Link, {
                                href: RouterService.getPublicLoginAsVisitorUrl(),
                                selector: "a.hyperlink"
                            },
                            i18n.t("sign_up_again")
                        ),
                        "."
                    ),
                    m(Contact_Support_team)
                ])
            ])
        ];
    }
};
export default Component_Visitor_Registration_Success;
