import m from 'mithril';
import Requester from '../../shared/request';
import Gateway from '../../shared/app_settings';
import Toasts from '../../shared/toasts';
import i18n from '../../shared/i18n/i18n';
import Component_Unit_List_Item from './component_unit_list_item';
import SessionController from '../../shared/session';
import Loading from '../../shared/loading';
import RouterService from '../../shared/services/router.service';
import ModalManager from '../../shared/modal';
import Modal_Confirm from '../../shared/components/modal_confirm';
import {getCourseBackgroundClass, getCourseLabelResult} from "../../shared/utilities/status_utilities";
import {getCourseEnrolmentStatus} from "../../shared/utilities/courseEnrolmentStatus_utilities";

let loading, enrolling, course;

function loadCourse(id) {
    loading = true;
    Requester.get(Gateway.public + 'courses/' + id)
        .then(function (result) {
            course = result;
        })
        .catch(function (error) {
            Toasts.add('error', i18n.t('error'), i18n.t(error.response.properties.errorCode));
        })
        .finally(function () {
            enrolling = false;
            loading = false;
        });
}

function reloadCourse() {
    Requester.get(Gateway.public + 'courses/' + course.id + (course.enrolment.Id ? '?enrolmentId=' + course.enrolment.Id : ''))
        .then(function (result) {
            course.units = [];
            course = result;
        })
        .catch(function (error) {
            Toasts.add('error', i18n.t('error'), i18n.t(error.response.properties.errorCode));
        })
        .finally(function () {
            enrolling = false;
            loading = false;
        });
}

function enrol() {
    enrolling = true;
    if (SessionController.session.identity) {
        start();
    } else {
        RouterService.navigateToPublicLoginWithCourseRedirect(course.id, course.allowGuestEnrolment);
    }
}

function start() {
    Requester.post(Gateway.public + 'enrolments/enrol/' + course.id)
        .then(function (result) {
            course.enrolment = { id: result };
            reloadCourse();
        })
        .catch(function (error) {
            Toasts.add('error', i18n.t('error'), i18n.t(error.response.Message));
        });
}

function confirmReenrolmentPrompt() {
    const attributes = {
        confirmCallback: enrol,
        cancelCallback: null,
        messageText: i18n.t("confirm_reenrolment_message_learner"),
        confirmButtonLabel: i18n.t("reenrol"),
        cancelButtonLabel: i18n.t("cancel"),
        confirmButtonClasses: "button",
        cancelButtonClasses: "button.btn-error"
    };
    ModalManager.open(i18n.t("confirm_reenrolment"), [], Modal_Confirm, attributes)
}

function hideReenrolButtonForGuests(allowGuestEnrolment) {
    if (SessionController.session.identity.isGuest && !allowGuestEnrolment) {
        return true;
    }

    return false;
}

let currentPage = '';
let unitNumber = null;
const PublicPage_Course = {
    oninit: function (vnode) {
        currentPage = 'courses';
        loadCourse(vnode.attrs.id);
        unitNumber = vnode.attrs.unitNumber;
    },
    view: function () {
        return [
            !loading ? [
                course ? [
                    m('.SecondaryTheme', [
                        m('.flex-column course-page-header', [
                            m('.centered-layout', [
                                m('.hero', [
                                    m('.hero-content', [
                                        m('h1', course.name),
                                        !enrolling ? [
                                            course.enrolment ? [
                                                m('.flex-row align-center ', [
                                                    m('.rounded-pill ml-2', { class: getCourseBackgroundClass(course.enrolment) }, getCourseEnrolmentStatus(course.enrolment, false, loading)),
                                                    !hideReenrolButtonForGuests(course.allowGuestEnrolment) && course.enrolment.isCompleted && course.allowReEnrolment && course.allowSelfEnrolment ? m('button.btn.btn-primary ml-2', { style: 'margin-bottom: 0; padding: 15px 3rem;', onclick: confirmReenrolmentPrompt }, i18n.t("reenrol")) : ''
                                                ]),
                                            ] : [
                                                course.allowSelfEnrolment ? m('button.btn.btn-primary', {
                                                    style: 'padding: 0 3rem;',
                                                    onclick: enrol
                                                }, i18n.t("enrol")) : ''
                                            ]
                                        ] : m(Loading, { class: 'loading-inline loading-invert align-left' })
                                    ]),
                                    m('.hero-img backslash-polygon', { style: !!course.imagePath ? 'background-image: url(\'' + course.imagePath + '\')' : '' })
                                ]),
                            ])
                        ]),
                        m('.flex-column course-page-content', [
                            m('.centered-layout center-this flex-row pt-0', [
                                m('.course-info-section', [
                                    m('.richtext', [
                                        m('h2.pb-2', i18n.t('aboutThisCourse')),
                                        m('.courseDescription', [
                                            m.trust(course.description ? course.description : '')
                                        ])
                                    ])
                                ])
                            ]),
                            m('.centered-layout center-this flex-row pt-0', [
                                m('.course-content-section', [
                                    m('h2.pb-2', i18n.t('completionSteps')),
                                    course.units && course.units.length > 0 ? [
                                        m('.big-number-list', [
                                            course.units.map(function (unit, index) {
                                                return m(Component_Unit_List_Item, {
                                                    unit: unit,
                                                    unitNumber: unitNumber,
                                                    course: course,
                                                    reloadCallback: reloadCourse,
                                                    enrolling: enrolling
                                                });
                                            })
                                        ])
                                    ] : m('.text-gray', i18n.t("no_units"))
                                ])
                            ])
                        ]),
                    ]),
                ] : m('.text-center center-this', i18n.t('cant_load'))
            ] : m(Loading)
        ];
    }
};
export default PublicPage_Course;
