﻿import m from "mithril";
import Requester from "../../shared/request";
import Gateway from "../../shared/app_settings";
import Toasts from "../../shared/toasts";
import i18n from "../../shared/i18n/i18n";
import SessionController from "../../shared/session";
import FormUtilities from "../../shared/utilities/form_utilities";
import AuthLogo from "../../shared/components/component_logo";
import Loading from "../../shared/loading";
import RouterService from "../../shared/services/router.service";

let loading,
    saving,
    email;

function submit() {
    if (FormUtilities.validateFields("input")) {
        saving = true;
        Requester.post(Gateway.identity + "forgot-password", email)
            .then(function () {
                RouterService.navigateToPublicForgotPasswordSent();
            })
            .catch(function () {
                Toasts.add("error", i18n.t("error"), i18n.t(error.response.properties.errorCode));
            })
            .finally(function () {
                saving = false;
            });
    }
}

const PublicPage_Password_Forgot = {
        oninit: function () {
            loading = true;

            if (SessionController.session && SessionController.session.identity && !SessionController.sso()) {
                RouterService.navigateOnLogin();
                Toasts.add("success", i18n.t("already_logged_in"), "");
            }

            if (SessionController.session && SessionController.session.environment && SessionController.session.environment.useExternalLogin && SessionController.session.environment.externalLoginUrl)
                window.location = SessionController.session.environment.externalLoginUrl;

            loading = false;
        },
        view: function () {
            return [
                m("div", [
                    !loading ? [
                        m(AuthLogo),
                        m(".form-header", i18n.t("forgot_password")),
                        m("form", { onsubmit: function (e) { e.preventDefault(); submit(); } }, [
                            m(".form-section", [
                                i18n.t("forgot_password_info")
                            ]),
                            m(".form-section required", [
                                m(".form-label", i18n.t("email")),
                                m("input[type=text]", { oninput: function () { email = this.value; }, value: email })
                            ]),
                            m(".form-buttons", [
                                !saving ? m(m.route.Link, { href: RouterService.getPublicLoginUrl(), class: "btn-text flex-float-left" }, i18n.t("back_to_login")) : "",
                                !saving ? m("button", { "type": "submit" }, i18n.t("submit")) : m("button.btn-text no-click", [i18n.t("submit"), m(".loading-dots")])
                            ])
                        ])
                    ] : m(Loading, { class: "loading-inline" })
                ])
            ];
        }
};
export default PublicPage_Password_Forgot;
