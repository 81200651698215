﻿import m from "mithril";
import FormUtilities from "../../shared/utilities/form_utilities";
import Requester from "../../shared/request";
import Gateway from "../../shared/app_settings";
import ModalManager from "../../shared/modal";
import Toasts from "../../shared/toasts";
import i18n from "../../shared/i18n/i18n";
import UniqueName from "../../shared/uniqueName";
import RouterService from "../../shared/services/router.service";

let saving = false;
let course = {
    name: ""
};
let modalId;

function saveCourse() {
    if (FormUtilities.validateFields(".modal input")) {
        saving = true;
        Requester.post(Gateway.admin + "courses", course)
            .then(function (result) {
                ModalManager.close(modalId);
                RouterService.navigateToCourse(result);
            }).catch(function (error) {
            Toasts.add("error", i18n.t("error"), i18n.t(error.response.properties.errorCode));
        }).finally(function () {
            saving = false;
        });
    }
}

const Modal_Course_New = {
    oninit: function() {
        course = {
            name: ""
        };
    },
    view: function () {
        return [
            m(".form-section required", [
                m(".form-label", i18n.t("name")),
                m("input[type=text]", {
                    onchange: function () {
                        UniqueName.checkUniqueCourseName("new", this.value)
                    },
                    oninput: function () {
                        course.name = this.value;
                    }, value: course.name
                })
            ]),
            m(".form-buttons", [
                !saving && (UniqueName.isCourseNameUnique) && !(UniqueName.courseLoading) ? m("button", {onclick: saveCourse}, i18n.t("save")) : UniqueName.isCourseNameUnique ? m("button.btn-text no-click", [i18n.t("saving"), m(".loading-dots")]) :
                    m("button.btn-text no-click", [i18n.t("save")])
            ])
        ];
    },
    setModalId: function(id) {
        modalId = id;
    }
};
export default Modal_Course_New;
