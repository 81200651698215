﻿import m from "mithril";
import Requester from "../../../shared/request";
import Gateway from "../../../shared/app_settings";
import i18n from "../../../shared/i18n/i18n";
import Toasts from "../../../shared/toasts";
import ModalManager from "../../../shared/modal";
import FormUtilities from "../../../shared/utilities/form_utilities";
import UniqueName from "../../../shared/uniqueName";
import RouterService from "../../../shared/services/router.service";

let saving = false;
let unit = {
    name: "",
    description: "",
    information: "",
    location: "",
    recurring: false,
    periodicity: null,
    startDate: "",
    endDate: "",
    startTime: "",
    endTime: "",
    minPlaces: null,
    maxPlaces: null,
    cutoffDaysBefore: null,
    allowDaysBefore: null,
    days: [],
    isExclusive: true,
    preventOverlap: true
};
let modalId;

function saveUnit() {
    if (unit.recurring) {
        let daySelect = document.getElementById("new-event-days-select");
        for (let i = 0; i < daySelect.options.length; i++)
            if (daySelect.options[i].selected)
                unit.days.push(daySelect.options[i].value);
    }

    if (FormUtilities.validateFields(".modal input")) {
        saving = true;
        Requester.post(Gateway.admin + "units/event", unit)
            .then(function (result) {
                ModalManager.close(modalId);
                RouterService.navigateToUnit(result);
            }).catch(function (error) {
            Toasts.add("error", i18n.t("error"), i18n.t(error.response.properties.errorCode));
        }).finally(function () {
            saving = false;
        });
    }
}

const Modal_Unit_Event_New = {
    oninit: function() {
        unit = {
            name: "",
            description: ""
        };
    },
    view: function () {
        return [
            m(".form-section required", [
                m(".form-label", i18n.t("name")),
                m("input[type=text]", {
                    onchange: function () {
                        UniqueName.checkUniqueUnitName("new", this.value)
                    },
                    oninput: function () {
                        unit.name = this.value;
                    }, value: unit.name
                })
            ]),
            m(".form-section", [
                m(".form-label", i18n.t("description")),
                m("textarea", {
                    oninput: function () {
                        unit.description = this.value;
                    }, value: unit.description
                })
            ]),
            m(".form-section", [
                m(".form-label", i18n.t("info")),
                m("textarea", {
                    oninput: function () {
                        unit.information = this.value;
                    }, value: unit.information
                })
            ]),
            m(".form-section", [
                m(".form-label", i18n.t("location")),
                m("input[type=text]", {
                    oninput: function () {
                        unit.location = this.value;
                    }, value: unit.location
                })
            ]),
            m(".form-buttons", [
                !saving && (UniqueName.isUnitNameUnique) && !(UniqueName.unitLoading) ? m("button", {onclick: saveUnit}, i18n.t("save")) : (UniqueName.isUnitNameUnique) ? m("button.btn-text no-click", [i18n.t("saving"), m(".loading-dots")]) :
                    m("button.btn-text no-click", [i18n.t("save")])
            ])
        ];
    },
    setModalId: function(id) {
        modalId = id;
    }
};
export default Modal_Unit_Event_New;
