﻿import m from "mithril";
import i18n from "../../shared/i18n/i18n";

let loading = false;
let stats = {
    allEnrolmentsCount: 7,
    enrolments: [
        {
            class: "chart-passed",
            amount: 3,
            size: 160,
            start: 0
        },
        {
            class: "chart-failed",
            amount: 2,
            size: 100,
            start: 160
        },
        {
            class: "chart-in-progress",
            amount: 2,
            size: 100,
            start: 260
        }
    ]
};

function createReport() {

}
let currentPage;
const Page_Reports = {
    oninit: function () {
        currentPage = "report";
    },
    view: function () {
        return [
            m(".content-wrapper", [
                m(".content", [
                    m(".panel-box", [
                        m(".panel-box-content", [
                            m(".col-15 sidebar", [
                                m("div", [
                                    m(".sidebar-header", i18n.t("reports")),
                                    m(".text-bolder", i18n.t("all_statistics"))
                                ]),
                                m("div", {class: loading ? "disable-buttons" : ""}, [
                                    m(".sidebar-header", i18n.t("actions")),
                                    m("button", {onclick: createReport}, i18n.t("create_report"))
                                ])
                            ]),
                            m(".col-85 page-content", [

                                /* --- */
                                m(".stats-box", [
                                    m("h5", i18n.t("all_enrolments")),
                                    m(".pie-chart", [
                                        stats.enrolments.map(function (c) {
                                            return [
                                                m(".pie-part", {
                                                    class: c.class,
                                                    style: (c.size > 180 ? "clip: auto;" : ""),
                                                    oncreate: function (e) {
                                                        setTimeout(function () {
                                                            e.dom.style.transform = "rotate(" + c.start + "deg)";
                                                        }, 1);
                                                    }
                                                }, m(".pie-slice", {style: "transform: rotate(" + c.size + "deg); " + (c.size > 180 ? "clip: auto;" : "")}))
                                            ];
                                        }), m(".pie-center")
                                    ]),
                                    m("ul.chart-stats", [
                                        stats.enrolments.length > 0 ? [
                                            stats.enrolments.map(function (c) {
                                                return [
                                                    m("li", {class: c.class}, [
                                                        m(".dot"),
                                                        m("label"),
                                                        m("span", c.amount)
                                                    ])
                                                ];
                                            }),
                                            m("li", [
                                                m("label", i18n.t("total")),
                                                m("span", stats.allEnrolmentsCount)
                                            ])
                                        ] : m("i", i18n.t("no_enrolments"))
                                    ])
                                ]),
                                /* --- */

                                !loading ? [
                                    stats ? [] : m(".text-gray padding", i18n.t("unable_to_load_stats"))
                                ] : m(".text-gray padding", [i18n.t("loading"), m(".loading-dots")])
                            ])
                        ])
                    ])
                ])
            ])
        ];
    }
};
export default Page_Reports;
