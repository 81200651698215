﻿import m from "mithril";
import Requester from "../../../shared/request";
import Gateway from "../../../shared/app_settings";
import i18n from "../../../shared/i18n/i18n";
import Toasts from "../../../shared/toasts";
import ModalManager from "../../../shared/modal";
import FormUtilities from "../../../shared/utilities/form_utilities";
import UniqueName from "../../../shared/uniqueName";
import RouterService from "../../../shared/services/router.service";

let saving = false;
let uploading = false;
let unit = {
    name: "",
    description: ""
};
let modalId;

function saveUnit() {
    const file = document.getElementById("upload-file-input").files[0];
    if (FormUtilities.validateFields(".modal input")) {
        saving = true;
        Requester.post(Gateway.admin + "units/download", unit)
            .then(function (resultDownloadUnitId) {
                if (file) {
                    uploadFile(resultDownloadUnitId);
                }
                else {
                    saving = false;
                    ModalManager.close(modalId);
                    RouterService.navigateToUnit(resultDownloadUnitId);
                }
            }).catch(function (error) {
            saving = false;
            Toasts.add("error", i18n.t("error"), i18n.t(error.response.properties.errorCode));
        });
    }
}

function uploadFile(downloadUnitId) {
    const file = document.getElementById("upload-file-input").files[0];
    let d = new FormData();
    if (file) {
        d.append("file", file, file.name);
    }

    saving = false;
    uploading = true;

    Requester.upload(Gateway.admin + "units/download/" + downloadUnitId + "/upload-file", d)
        .then(function () {
            Toasts.add("success", i18n.t("saved_changes"), "");
            ModalManager.close(modalId);
            RouterService.navigateToUnit(downloadUnitId);
        })
        .catch(function (error) {
            Toasts.add("error", i18n.t("error"), i18n.t(error.response.properties.errorCode));
        })
        .finally(function () {
            uploading = false;
        });
}

const Modal_Unit_Download_New = {
    oninit: function() {
        unit = {
            name: "",
            description: ""
        };
    },
    view: function () {
        return [
            m(".form-section required", [
                m(".form-label", i18n.t("name")),
                m("input[type=text]", { onchange: function () {
                        UniqueName.checkUniqueUnitName("new", this.value)
                    },
                    oninput: function () { unit.name = this.value; }, value: unit.name })
            ]),
            m(".form-section", [
                m(".form-label", i18n.t("description")),
                m("textarea", { oninput: function () { unit.description = this.value; }, value: unit.description })
            ]),
            m(".form-section", [
                m(".form-label", i18n.t("file")),
                m("input[type=file].form-input#upload-file-input"),
                uploading ? [
                    m(".loading-bar mt-1", m(".loading-width"))
                ] : ""
            ]),
            m(".form-buttons", [
                !saving && !uploading && (UniqueName.isUnitNameUnique) && !(UniqueName.unitLoading) ? m("button", { onclick: saveUnit }, i18n.t("save")) : (UniqueName.isUnitNameUnique) ? m("button.btn-text no-click", [i18n.t("saving"), m(".loading-dots")]) :
                    m("button.btn-text no-click", [i18n.t("save")])
            ])
        ];
    },
    setModalId: function(id) {
        modalId = id;
    }
};
export default Modal_Unit_Download_New;
