﻿import m from "mithril";
import Requester from "../../shared/request";
import Toasts from "../../shared/toasts";
import Loading from "../../shared/loading";
import i18n from "../../shared/i18n/i18n";
import ResponsiveUtilities from "../../shared/utilities/responsive_utilities";
let src;

function load(url) {
    Requester.getFile(url)
        .then(function (result) {
            let blob = new Blob([result], { type: 'application/pdf' });
            src = URL.createObjectURL(blob);
        }).catch(function (error) {
            Toasts.add("error", i18n.t("error"), i18n.t(error.response.properties.errorCode));
        });
}


const PublicModal_Certificate_Preview = {
    oninit: function (vnode) {
        load(vnode.attrs.url);
    },
    view: function (vnode) {
        return [
            src ? [
                ResponsiveUtilities.mobileAndTabletCheck()
                ? m("div", { class: "centre-content" }, [
                    m("p", i18n.t("doesnt_support_previewing_pdfs")),
                    m("p", {}, [m("a", { class: "hyperlink", target: "_blank", href: src }, i18n.t("download_instead"))])
                ])
                : m("object", { data: src, class: "flex-auto", type: "application/pdf", width: "100%", height: "100%" })
            ] : m(".form-section", m(Loading))
        ];
    }
};
export default PublicModal_Certificate_Preview;
