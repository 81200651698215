﻿import Requester from '../../shared/request';
import Gateway from '../../shared/app_settings';
import Toasts from '../../shared/toasts';
import Loading from '../../shared/loading';
import SessionController from '../../shared/session';
import i18n from "../../shared/i18n/i18n";

let loading = false;
let orgs = [];

function loadOrgs() {
    loading = true;
    Requester.get(Gateway.identity + "organisations")
        .then(function (result) {
            orgs = result;
        })
        .catch(function (error) {
            Toasts.add("error", i18n.t("error"), i18n.t(error.response.properties.errorCode));
        })
        .then(function () {
            loading = false;
        });
}

function visitOrg(sub) {
    window.location = "https://" + sub + "." + location.host.split('.').slice(1).join('.');
}

const Page_No_Account = {
    oninit: function () {
        loadOrgs();
    },
    view: function () {
        return [
            m("div", [
                !loading ? [
                    m(".form-header", i18n.t("account_not_found")),
                    m("div", [
                        m(".form-section", [
                            i18n.t("a_spud_lms_account_is_required_to_view_the_account_portal"),
                            m("br"),
                            orgs && orgs.length > 0 ? [
                                m("br"),
                                i18n.t("visit_organisation_portal"),
                                m("br"),
                                m("br"),
                                orgs.map(function (org) {
                                    return [
                                        m(".mb-2", [
                                            m("i.icon-forward mr-1"),
                                            org.orgName,
                                            m("button.btn-text ml-1", {
                                                onclick: function () {
                                                    visitOrg(org.subdomain);
                                                }
                                            }, i18n.t("log_in"))
                                        ])
                                    ];
                                })
                            ] : ""
                        ]),
                        m(".form-buttons", [
                            m("button.btn-error flex-float-left mt-5 ml-0", {onclick: SessionController.logout}, i18n.t("logout"))
                        ])
                    ])
                ] : m(Loading)
            ])
        ];
    }
};

export default Page_No_Account;
