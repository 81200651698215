﻿import m from "mithril";
import FormUtilities from "../../shared/utilities/form_utilities";
import Requester from "../../shared/request";
import Gateway from "../../shared/app_settings";
import Toasts from "../../shared/toasts";
import i18n from "../../shared/i18n/i18n";
import Loading from "../../shared/loading";
import SessionController from "../../shared/session";
import RouterService from "../../shared/services/router.service";

let loading = false;
let saving = false;
let confirmPassword = null;
let values = {};

function submit() {
    if (FormUtilities.validateFields("input") && values.password === confirmPassword) {
        saving = true;
        Requester.post(Gateway.identity + "reset-password", values)
            .then(function () {
                RouterService.navigateToPublicLogin();
                Toasts.add("success", i18n.t("password_updated"), i18n.t("please_log_in"));
            })
            .catch(function (error) {
                Toasts.add("error", i18n.t("error"), i18n.t(error.response.properties.errorCode));
                saving = false;
            });
    } else
        Toasts.add("error", i18n.t("cant_save"), i18n.t("ensure_both_password_fields_match"));
}

const Page_Password_Reset = {
    oninit: function () {
        loading = true;
        values = {
            password: null,
            user: m.route.param("user"),
            token: m.route.param("token")
        };

        if (SessionController.session && SessionController.session.identity && !SessionController.sso()) {
            RouterService.navigateToAccount();
            Toasts.add("success", i18n.t("already_logged_in"), "");
        }

        loading = false;
    },
    view: function () {
        return [
            m("div", [
                !loading ? [
                    m(".form-header", i18n.t("set_new_password")),
                    m("form", {
                        onsubmit: function (e) {
                            e.preventDefault();
                            submit();
                        }
                    }, [
                        m(".form-section required", [
                            m(".form-label", i18n.t("new_password")),
                            m("input[type=password]", {
                                oninput: function () {
                                    values.password = this.value;
                                }, value: values.password
                            })
                        ]),
                        m(".form-section required", [
                            m(".form-label", i18n.t("confirm_new_password")),
                            m("input[type=password]", {
                                oninput: function () {
                                    confirmPassword = this.value;
                                }, value: confirmPassword
                            })
                        ]),
                        m(".form-buttons", [
                            !saving ? m("button", {"type": "submit"}, i18n.t("submit")) : m("button.btn-text no-click", [i18n.t("submit"), m(".loading-dots")])
                        ])
                    ])
                ] : m(Loading, {class: "loading-inline"})
            ])
        ];
    }
};

export default Page_Password_Reset
