﻿import m from "mithril";
import Requester from "../../shared/request";
import Gateway from "../../shared/app_settings";
import Toasts from "../../shared/toasts";
import i18n from "../../shared/i18n/i18n";
import ModalManager from "../../shared/modal";

let reloadCallback;
let courseId = null;
let loading = false;
let saving = false;
let units = [];
let selectedCount = 0;
let search = [];
let modalId;

function loadUnits() {
    loading = true;
    Requester.get(Gateway.admin + "courses/" + courseId + "/available-units")
        .then(function (result) {
            if (!!search.length) {
                let searchedUnits = [];
                let allUnits = result.properties.units
                for (let i = 0; i < result.properties.units.length; i++) {
                    if (allUnits[i].name.toLowerCase().includes(search.toLowerCase())
                        || (allUnits[i].type.toLowerCase().includes(search.toLowerCase()))) {
                        searchedUnits.push(result.properties.units[i])
                    }
                    units = searchedUnits;
                }
            }
            if (!search.length) {
                units = result.properties.units;
            }
        }).catch(function (error) {
        Toasts.add("error", i18n.t("error"), i18n.t(error.response.properties.errorCode));
    }).finally(function () {
        loading = false;
    });
}

function getSelectionCount() {
    selectedCount = 0;
    const sel = document.getElementById("course-add-units-select");
    for (let i = 0; i < sel.options.length; i++) {
        if (sel.options[i].selected)
            selectedCount++;
    }
}

function addUnits() {
    saving = true;
    const selectedUnits = [];
    const sel = document.getElementById("course-add-units-select");
    for (let i = 0; i < sel.options.length; i++) {
        if (sel.options[i].selected)
            selectedUnits.push(sel.options[i].value);
    }

    if (selectedUnits.length === 0) {
        Toasts.add("error", i18n.t("cant_save"), i18n.t("select_units"));
        saving = false;
    } else {
        Requester.post(Gateway.admin + "courses/" + courseId + "/add-units", {
            courseId: courseId,
            unitIds: selectedUnits
        })
            .then(function (result) {
                ModalManager.close(modalId);
                reloadCallback();
            }).catch(function (error) {
            Toasts.add("error", i18n.t("error"), i18n.t(error.response.properties.errorCode));
        }).finally(function () {
            saving = false;
        });
    }
}

const Modal_Course_Units_Add = {
    oninit: function (vnode) {
        search = []
        reloadCallback = vnode.attrs.reloadCallback;
        courseId = vnode.attrs.courseId;
        loadUnits();
    },
    view: function () {
        return [
            m(".form-section required", [
                !loading ? [
                    units ? [
                        m(".mb-1", [selectedCount, " ", i18n.t("selected")]),
                        m(".has-icon-right", [
                            m("input[type=search]", {
                                oninput: function () {
                                    search = this.value;
                                }, onkeyup: function (e) {
                                    if (e.keyCode === 13) {
                                        loadUnits()
                                    }
                                }, value: search, placeholder: i18n.t("search")
                            })
                        ]),
                        m("select[multiple]#course-add-units-select", {onchange: getSelectionCount},
                            units.map(function (unit) {
                                return [
                                    m("option.mb-2", {value: unit.id}, [unit.name, " (", unit.type, ")"])
                                ];
                            }))
                    ] : i18n.t("no_units")
                ] : [i18n.t("loading"), m(".loading-dots")]
            ]),
            m("p", i18n.t("remember_to_sync_changes")),
            m(".form-buttons", [
                !saving ? m("button", {onclick: addUnits}, i18n.t("save")) : m("button.btn-text no-click", [i18n.t("saving"), m(".loading-dots")])
            ])
        ];
    },
    setModalId: function(id) {
        modalId = id;
    }
};
export default Modal_Course_Units_Add;
