﻿import m from "mithril";
import i18n from "../../shared/i18n/i18n";
import Toasts from "../../shared/toasts";
import ModalManager from "../../shared/modal";
import RoleService from "../../shared/services/role.service";
import UserService from "../../shared/services/user.service";
import SessionController from "../../shared/session";

let userIds, loading, saving, roles, callback, modalId, isInternalRole, selectedRole, selectedIndex;

function loadRoles() {
    loading = true;
    UserService.getAssignableRoles(isInternalRole)
        .then(function (result) {
            roles = result;
        })
        .catch(function (error) {
            Toasts.add("error", i18n.t("error"), i18n.t(error.response.properties.errorCode));
        })
        .finally(function () {
            initialiseSelectedIndex();
            loading = false;
        });
}

function changeRole() {
    saving = true;
    UserService.updateRole(userIds, selectedRole)
        .then(function () {
            ModalManager.close(modalId);
            Toasts.add("success", i18n.t("rolesUpdated"));
            if (callback) {
                callback();
            }
        })
        .catch(function (error) {
            Toasts.add("error", i18n.t("error"), i18n.t(error.response.properties.errorCode));
        })
        .finally(function () {
            saving = false;
        });
}

function getRoleLabel(role) {
    let label = RoleService.getAccountRoleLabel(role);
    label = i18n.t(label);
    if (role === RoleService.roles.learner) {
        label = label.replace('{0}', SessionController.session.environment.name);
    }
    return label;
}

function initialiseSelectedIndex() {
    if (!roles) {
        selectedIndex = 0;
        selectedRole = null;
        return;
    }

    if (selectedRole) {
        selectedIndex = roles.indexOf(selectedRole);
        if (selectedIndex < 0) {
            selectedIndex = 0;
        }
    } else {
        selectedIndex = 0;
    }
    
    selectedRole = roles[selectedIndex] ? roles[selectedIndex]: null;
}

const Modal_User_Change_Role = {
    oninit: function (vnode) {
        userIds = vnode.attrs.userIds;
        selectedRole = vnode.attrs.selectedRole;
        isInternalRole = vnode.attrs.isInternalRole;
        callback = vnode.attrs.callback;
        loadRoles();
    },
    view: function () {
        return [
            m(".form-section required", [
                !loading ? [
                    roles ? [
                        m(".form-label", i18n.t("selectRole")),
                        m("select", {
                                selectedIndex: selectedIndex,
                                onchange: function () {
                                    selectedRole = this.value;
                                    selectedIndex = roles.indexOf(selectedRole);
                                }
                            },
                            roles.map(function (role) {
                                return [
                                    m("option", {value: role}, getRoleLabel(role))
                                ];
                            }))
                    ] : "No roles"
                ] : [i18n.t("loading"), m(".loading-dots")]
            ]),
            m(".form-buttons", [
                !saving ? m("button", {onclick: changeRole}, i18n.t("save")) : m("button.btn-text no-click", [i18n.t("saving"), m(".loading-dots")])
            ])
        ];
    },
    setModalId: function(id) {
        modalId = id;
    }
};
export default Modal_User_Change_Role;
