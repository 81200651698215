﻿import m from 'mithril';
import Requester from '../../shared/request';
import Gateway from '../../shared/app_settings';
import Toasts from '../../shared/toasts';
import i18n from '../../shared/i18n/i18n';

function completeUnit(unit, course) {
    Requester.post(Gateway.public + 'units/download/' + unit.enrolment.id + '?enrolmentId=' + course.enrolment.id)
        .then(function () {
            downloadFile(unit);
        })
        .catch(function (error) {
            Toasts.add('error', i18n.t('error'), i18n.t(error.response.properties.errorCode));
        });
}

function downloadFile(unit) {
    Requester.get(Gateway.public + 'units/download/' + unit.id)
        .then(function (unit) {
            if (!unit.filePath)
                Toasts.add('error', i18n.t('file_not_found'));
            else
                window.open(unit.filePath, '_self');

            reloadCallback();
        })
        .catch(function (error) {
            Toasts.add('error', i18n.t('error'), i18n.t(error.response.properties.errorCode));
        });
}

let reloadCallback;
const Component_Unit_Actions_Download = {
    oninit: function (vnode) {
        let unit = vnode.attrs.unit;
        let course = vnode.attrs.course;
        let unitNumber = vnode.attrs.unitNumber;
        reloadCallback = vnode.attrs.reloadCallback;
        if (unit && course && unitNumber && unit.id === unitNumber) {
            return unit?.enrolment?.allowAccess ?
                unit.enrolment.isCompleted ?
                    downloadFile(unit) : completeUnit(unit, course) : ''
        }
    },
    view: function (vnode) {
        let unit = vnode.attrs.unit;
        let course = vnode.attrs.course;
        reloadCallback = vnode.attrs.reloadCallback;
        return [
            unit?.enrolment?.allowAccess ?
                m('button', {
                    onclick: unit.enrolment.isCompleted ? function () {
                        downloadFile(unit);
                    } : function () {
                        completeUnit(unit, course);
                    },
                    class: unit.enrolment.isCompleted ? '' : 'btn-cta'
                }, i18n.t('download'))
                : ''
        ];
    }
};

export default Component_Unit_Actions_Download;
