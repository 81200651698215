﻿import m from "mithril";
import Requester from "../../shared/request";
import Gateway from "../../shared/app_settings";
import Toasts from "../../shared/toasts";
import i18n from "../../shared/i18n/i18n";
import Loading from "../../shared/loading";
import SessionController from "../../shared/session";
import RouterService from "../../shared/services/router.service";
import Contact_Support_team from "./contact_support_team";
import { SUPPORT_LINK } from "../../shared/constants/constants";

let loading = true;
let enrolmentId;

const PublicPage_Verify_SSO_Proxy = {
    oninit: function (vnode) {
        enrolmentId = vnode.attrs.enrolmentId;
        loading = true;
        Requester.get(Gateway.public + `id/sso/is-account-active?enrolmentId=${enrolmentId}`)
                .then(function (result) {
                    if(result.isAccountActive) {
                        if (SessionController.session && SessionController.session.identity) {
                            RouterService.navigateToPublicCourse(result.courseId);
                        }
                        else {
                            Requester.get(Gateway.identity + "sso")
                                .then(function (ssoResult) {
                                    if(ssoResult && ssoResult.length) {
                                        window.location.href = ssoResult[0].url + `%2F%23%21%2Fpublic%2Fcourses%2F${result.courseId}`;
                                    }
                                    else {
                                        RouterService.navigateToPublicLogin();
                                    }
                                })
                                .catch(function (error) {
                                    RouterService.navigateToPublicLogin();
                                });
                        }
                    } else {
                        loading = false;
                    }
                })
                .catch(function (error) {
                    loading = false;
                    Toasts.add("error", i18n.t("error"), i18n.t(error.response.properties.errorCode));
                });
    },
    view: function () {
        return [
            loading ? m("div", {class: "loading-inline"}, i18n.t("loading_dots")) :
                m("#page-wrapper", [
                    m("#page", [
                        m("main.flex-row", [
                            m("div.#verify-sso-outer", [
                                m("h2#activate-peg-account.avetta-blue-heading", i18n.t("activate_pegasus_account")),
                                m("div.flex-col.verify-sso-container", [
                                    m("div", i18n.t("activate_sso_message")),
                                    m("div", [
                                        m("span", i18n.t("dont_recieve_email_message") + " "),
                                        m("a.hyperlink", {
                                            href: SUPPORT_LINK,
                                            target: "_blank"
                                        }, i18n.t("dont_receive_email_message_contact_support_team"))
                                    ]),
                                    m("button.full-btn.primary-btn", { onclick: resendInvite }, i18n.t("resend_activation_email")),
                                    m(Contact_Support_team)
                                ])
                            ])
                        ])
                    ])
                ]),
                m("#toaster")
            ];
    }
};

function resendInvite() {
    Requester.get(Gateway.public + `id/sso/resend-invite?enrolmentId=${enrolmentId}`)
        .then(function (result) {
            if(result.value) {
                Toasts.add("success", i18n.t("email_sent"), "");
            }
        })
        .catch(function (error) {
            Toasts.add("error", i18n.t("error"), i18n.t(error.response.properties.errorCode));
        });
}

export default PublicPage_Verify_SSO_Proxy;
