﻿import m from "mithril";
import FormUtilities from "../../shared/utilities/form_utilities";
import Toasts from "../../shared/toasts";
import i18n from "../../shared/i18n/i18n";
import Requester from "../../shared/request";
import Gateway from "../../shared/app_settings";
import ModalManager from "../../shared/modal";

let saving = false;
let emails = [];
let modalId;

function addEmail() {
    const emailField = document.getElementById("new-user-add-email-field");
    if (emailField.value && FormUtilities.validateEmail(emailField.value) && emails.indexOf(emailField.value) === -1) {
        emails.push(emailField.value);
        emailField.value = "";
        emailField.classList.remove("has-error");
    } else {
        emailField.classList.add("has-error");
    }
}

function removeEmail(email) {
    const index = emails.indexOf(email);
    if (index > -1)
        emails.splice(index, 1);
}

function inviteUsers() {
    if (!emails.length > 0)
        Toasts.add("error", i18n.t("cant_send"), i18n.t("at_least_one_email"));
    else {
        saving = true;
        Requester.post(Gateway.admin + "account/users/invite", emails)
            .then(function () {
                Toasts.add("success", i18n.t("sent"), "");
                //Logger.add("success", "Invite Users", emails.length + " invitations sent");
                ModalManager.close(modalId);
            })
            .catch(function (error) {
                Toasts.add("error", i18n.t("error"), i18n.t(error.response.properties.errorCode));
            })
            .finally(function () {
                saving = false;
            });
    }
}

const Modal_User_Invite = {
    view: function () {
        return [
            m(".form-section with-button", [
                m("input[type=email]#new-user-add-email-field[placeholder=example@email.com]", {
                    onkeyup: function (e) {
                        if (e.keyCode === 13) {
                            addEmail();
                        }
                    }
                }),
                m("button.active", {onclick: addEmail}, "+")
            ]),
            emails.map(function (email) {
                return [
                    m(".form-section", [
                        m("button.btn-text", {
                            onclick: function () {
                                removeEmail(email);
                            }
                        }, "x"),
                        email
                    ])
                ];
            }),
            m(".form-buttons", [
                !saving ? m("button", {onclick: inviteUsers}, i18n.t("save")) : m("button.btn-text no-click", [i18n.t("saving"), m(".loading-dots")])
            ])
        ];
    },
    setModalId: function(id) {
        modalId = id;
    }
};

export default Modal_User_Invite;
