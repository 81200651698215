﻿import m from "mithril";
import ModalManager from '../../shared/modal';
import i18n from "../../shared/i18n/i18n";
import { SUPPORT_LINK } from "../../shared/constants/constants";

let modalId, closeScormCourse;
const learnMoreLink = 'https://kb.pegasus.net.au/display/OCCS/Learning+Management+System';

function close() {
    ModalManager.close(modalId);
}

function learnMoreOnClick() {
    window.open(learnMoreLink);
    if (closeScormCourse) {
        closeScormCourse();
    }
    close();
}

const Modal_Scorm_Adblocker = {
    oninit: function (vnode) {
        closeScormCourse = vnode.attrs.closeScormCourse;
    },
    view: function () {
        return [
            m(".form-section", [
                i18n.t("adblocker_prompt_message"),
                m("br"),
                m("br"),
                i18n.t("need_help"),
                m("span", [m("a", {class: "hyperlink", target: "_blank", href: SUPPORT_LINK}, ' ' + i18n.t("contact_our_support_team"))]),
            ]),
            m(".form-buttons", [
                m("button", { onclick: learnMoreOnClick }, i18n.t("learn_more")),
                m("button", { onclick: close }, i18n.t("launch_course_anyway"))
            ])
        ];
    },
    setModalId: function(id) {
        modalId = id;
    }
};

export default Modal_Scorm_Adblocker;
