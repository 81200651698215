﻿import m from "mithril";
import Requester from "../../shared/request";
import Gateway from "../../shared/app_settings";
import Toasts from "../../shared/toasts";
import Auth from "../../shared/auth";
import Component_Course_Units from "./component_course_units";
import Component_Enrolments from "../enrolment/component_enrolments";
import Component_Course_Checklist from "./component_course_checklist";
import Component_Course_Details from "./component_course_details";
import Component_Course_Settings from "./component_course_settings";
import i18n from "../../shared/i18n/i18n";

let loading, course, tab = "units";
let currentPage = "courses";
let courseName = '';
function loadCourse(id) {
    loading = true;
    Requester.get(Gateway.admin + "courses/" + id)
        .then(function (result) {
            course = result
        }).catch(function (error) {
        Toasts.add("error", i18n.t("error"), i18n.t(error.response.properties.errorCode));
    }).finally(function () {
        loading = false;
        courseName = course?.properties.name;
    });
}
let panel, showPanel, selectedUnit;
function viewSettings(unit) {
    panel = "settings";
    showPanel = true;
    selectedUnit = unit;
    unit.selected = true;
}

function previewUnit(unit) {
    panel = "preview";
    showPanel = true;
    selectedUnit = unit;
    unit.selected = true;
}

function closePanel() {
    panel = null;
    showPanel = false;
    selectedUnit = null;
    m.redraw();
}

const Page_Course = {
    oninit: function (vnode) {
        courseName = "";
        loadCourse(vnode.attrs.id);
        currentPage = "courses";
    },
    view: function () {
        return [
            m(".content-box", [
                m(".toolbar", {class: loading ? "disable-buttons" : ""}, [
                    m(".toolbar-section with-menu", [
                        m("h1", i18n.t("course") + ": " + courseName),
                        m(".toolbar-menu", [
                            m("div", {
                                class: tab === "units" ? "active" : "", onclick: function () {
                                    tab = "units";
                                }
                            }, i18n.t("units")),
                            Auth.validateRights(["courses_edit_details"], m("div", {
                                class: tab === "details" ? "active" : "",
                                onclick: function () {
                                    tab = "details";
                                }
                            }, i18n.t("details"))),
                            Auth.validateRights(["courses_edit_settings"], m("div", {
                                class: tab === "settings" ? "active" : "",
                                onclick: function () {
                                    tab = "settings";
                                }
                            }, i18n.t("settings"))),
                            Auth.validateRights(["courses_edit_checklist"], m("div", {
                                class: tab === "checklist" ? "active" : "",
                                onclick: function () {
                                    tab = "checklist";
                                }
                            }, i18n.t("checklist"))),
                            Auth.validateRights(["enrolments_view"], m("div", {
                                class: tab === "enrolments" ? "active" : "",
                                onclick: function () {
                                    tab = "enrolments";
                                }
                            }, i18n.t("enrolments")))
                        ])
                    ])
                ])
            ]),
            !loading ? [
                course ? [
                    tab === "units" ? m(Component_Course_Units, {
                            course: course,
                            settingsCallback: viewSettings,
                            previewCallback: previewUnit,
                            closePanelCallback: closePanel
                        })
                        : tab === "enrolments" ? m(Component_Enrolments, {courseId: course.properties.id})
                            : tab === "checklist" ? m(Component_Course_Checklist, {courseId: course.properties.id})
                                : tab === "details" ? m(Component_Course_Details, {course: course.properties})
                                    : tab === "settings" ? m(Component_Course_Settings, {course: course.properties})
                                        : ""
                ] : m(".text-gray content-box", i18n.t("unable_to_load_course"))
            ] : m(".text-gray content-box", [i18n.t("loading"), m(".loading-dots")])
        ];
    }
};
export default Page_Course;
