﻿import m from "mithril";
import { TimesCircle } from '@mithril-icons/font-awesome/solid'
import i18n from "../../shared/i18n/i18n";
import Log_In_Button from "../auth/log_in_button";

let signUpCallback, header, message;

const Component_Visitor_Registration_Failed = {
    oninit: function(vnode) {
        signUpCallback = vnode.attrs.signUpCallback;
        header = vnode.attrs.header;
        message = vnode.attrs.message;
    },
    view: function () {
        return [
            m(".relative", [
                m("div.splash-container", [
                    m(TimesCircle, { width: 50, height: 50, fill: '#ff0000', class: 'form-section' }),
                    m('h2.text-center.form-section', i18n.t(header)),
                    m('p.text-center.form-section', i18n.t(message)),
                    m(Log_In_Button),
                    m("h5.or-line", m("span", i18n.t("or"))),
                    m("a.btn.fill-width.full-btn.login-as-visitor.centre-content", {
                        onclick: signUpCallback
                    },
                    i18n.t("sign_up_again"))
                ])
            ])
        ];
    }
};
export default Component_Visitor_Registration_Failed;
