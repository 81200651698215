﻿import m from "mithril";
import i18n from "../../../shared/i18n/i18n";

let array = [];

function addAgendaItem() {
    let timeField = document.getElementById("agenda-time-field");
    let infoField = document.getElementById("agenda-info-field");
    timeField.classList.remove("has-error");
    infoField.classList.remove("has-error");

    if (timeField.value && infoField.value) {
        array.push({
            time: timeField.value,
            info: infoField.value
        });
        timeField.value = "";
        infoField.value = "";
    }
    else {
        if (!timeField.value)
            timeField.classList.add("has-error");

        if (!infoField.value)
            infoField.classList.add("has-error");
    }
}

function removeAgendaItem(item) {
    let index = array.indexOf(item);
    if (index > -1)
        array.splice(index, 1);
}

function getPlaceHolder() {
   return i18n.t("morning_tea")
}

const Component_Agenda_Input = {
        oninit: function (vnode) {
            array = vnode.attrs.array;
        },
        view: function () {
            return [
                m(".form-section agenda-input", [
                    m(".form-label", i18n.t("agenda")),
                    m("input[type=time]#agenda-time-field"),
                    m("input[type=email]#agenda-info-field", {
                        placeholder: getPlaceHolder(),
                        onkeyup: function (e) { if (e.keyCode === 13) { addAgendaItem(); } } }),
                    m("button.active", { onclick: addAgendaItem }, "+")
                ]),
                array && array.length ? array.map(function (item) {
                    return [
                        m(".form-section", [
                            m("button.btn-text", { onclick: function () { removeAgendaItem(item); } }, "x"),
                            item.time, " - ", item.info
                        ])
                    ];
                }): "",
                array && array.length ? m("hr") : "",
            ];
        }
};
export default  Component_Agenda_Input;
