﻿import m from "mithril";
import Requester from "../../shared/request";
import Gateway from "../../shared/app_settings";
import Toasts from "../../shared/toasts";
import ModalManager from "../../shared/modal";
import Auth from "../../shared/auth";
import i18n from "../../shared/i18n/i18n";
import Modal_Confirm_Delete from "../../shared/components/modal_confirm_delete";

let org, settings, configs, configIds, loading, loadingConfigs;

function getOrganisationSettings() {
    loading = true;
    Requester.get(Gateway.admin + "account/organisations/" + org.id + "/settings")
        .then(function (result) {
            settings = result;
        })
        .catch(function (error) {
            Toasts.add("error", i18n.t("error"), i18n.t(error.response.properties.errorCode));
        })
        .finally(function () {
            loading = false;
        });
}

function toggleRedirectToLogin() {
    Requester.post(Gateway.admin + "account/organisations/" + org.id + "/toggle-redirect-login")
        .then(function () {
            settings.redirectToLogin = !settings.redirectToLogin;
        })
        .catch(function (error) {
            Toasts.add("error", i18n.t("error"), i18n.t(error.response.properties.errorCode));
        });
}

function toggleDisableSignup() {
    Requester.post(Gateway.admin + "account/organisations/" + org.id + "/toggle-disable-signup")
        .then(function () {
            settings.disableSignup = !settings.disableSignup;
        })
        .catch(function (error) {
            Toasts.add("error", i18n.t("error"), i18n.t(error.response.properties.errorCode));
        });
}

function toggleDisableLogin() {
    Requester.post(Gateway.admin + "account/organisations/" + org.id + "/toggle-disable-login")
        .then(function () {
            settings.disableLogin = !settings.disableLogin;
        })
        .catch(function (error) {
            Toasts.add("error", i18n.t("error"), i18n.t(error.response.properties.errorCode));
        });
}

function reloadSsoConfigs() {
    setTimeout(getSsoConfigs, 500);
}

function getSsoConfigs() {
    loadingConfigs = true;
    Requester.get(Gateway.admin + "account/organisations/" + org.id + "/sso")
        .then(function (result) {
            configs = result.ssoConfigs;
            configIds = result.orgConfigIds;
        })
        .catch(function (error) {
            Toasts.add("error", i18n.t("error"), i18n.t(error.response.properties.errorCode));
        })
        .finally(function () {
            loadingConfigs = false;
        });
}

function toggleConfigId(id) {
    loadingConfigs = true;
    Requester.post(Gateway.admin + "account/organisations/" + org.id + "/sso/" + id)
        .then(function () {
            reloadSsoConfigs();
        })
        .catch(function (error) {
            Toasts.add("error", i18n.t("error"), i18n.t(error.response.properties.errorCode));
            loadingConfigs = false;
        });
}

function confirmNewPrimaryKey() {
    ModalManager.open(i18n.t("new_primary_api_key"), [], Modal_Confirm_Delete, {
        callback: newPrimaryApiKey,
        text: i18n.t("sure_to_generate_new_api_key"),
        btnText: i18n.t("generate_new_key")
    });
}

function newPrimaryApiKey() {
    loading = true;
    Requester.post(Gateway.admin + "account/api-new-org-live-token?id=" + org.id)
        .then(function (result) {
            settings.apiKey = result;
        })
        .catch(function (error) {
            Toasts.add("error", i18n.t("error"), i18n.t(error.response.properties.errorCode));
        })
        .finally(function () {
            loading = false;
        });
}

const Component_Organisation_Settings = {
    oninit: function (vnode) {
        org = vnode.attrs.org;
        getOrganisationSettings();
        getSsoConfigs();
    },
    view: function () {
        return [
            org ? [
                m(".content-box", [
                    m("h3", i18n.t("organisation_access")),
                    !loading ? [
                        settings ? [
                            m(".form", {class: !Auth.validateRights(["org_edit"]) ? "disabled" : ""}, [
                                m(".form-section form-section-narrow flex-row justify-between", [
                                    m(".text-bolder", i18n.t("allow_visitor_registration")),
                                    m(".btn btn-toggle", {
                                        onclick: toggleDisableSignup,
                                        class: !settings.disableSignup ? "active" : ""
                                    })
                                ]),
                                m(".form-section form-section-narrow flex-row justify-between", [
                                    m(".text-bolder", i18n.t("disable_default_log_in")),
                                    m(".btn btn-toggle", {
                                        onclick: toggleDisableLogin,
                                        class: settings.disableLogin ? "active" : ""
                                    })
                                ]),
                                m(".form-section form-section-narrow flex-row justify-between", [
                                    m(".text-bolder", i18n.t("redirect_logged-out_users")),
                                    m(".btn btn-toggle", {
                                        onclick: toggleRedirectToLogin,
                                        class: settings.redirectToLogin ? "active" : ""
                                    })
                                ])
                            ])
                        ] : m(".text-gray padding", i18n.t("unable_to_load_settings"))
                    ] : m(".text-gray padding", [i18n.t("loading"), m(".loading-dots")])
                ]),
                m(".content-box", [
                    m("h3", i18n.t(("single_sign_on")),
                    !loadingConfigs ? [
                        configs && configIds ? [
                            configs.length > 0 ? [
                                m(".table-wrapper", [
                                    m("table.full-width no-hover", [
                                        m("thead",
                                            m("tr", [
                                                m("th.pl-2", i18n.t("name")),
                                                m("th", i18n.t("type")),
                                                m("th", i18n.t("service_url")),
                                                m("th", i18n.t("active"))
                                            ])
                                        ),
                                        m("tbody", configs.map(function (config) {
                                            return [
                                                m("tr", [
                                                    m("td.pl-2 text-bolder", m("span", config.name)),
                                                    m("td", config.type === "cas" ? "CAS" : config.type === "jwt" ? "JWT" : i18n.t("unknown")),
                                                    m("td", (org.hostname ? org.hostname : (org.subdomain + "." + location.host.split('.').slice(1).join('.'))) + "/login/" + config.type + "/" + config.code),
                                                    m("td", m(".text-larger", {
                                                        onclick: Auth.validateRights(["org_edit"], function () {
                                                            toggleConfigId(config.id);
                                                        }), class: Auth.validateRights(["org_edit"], "c-hand")
                                                    }, configIds.indexOf(config.id) !== -1 ? m("i.icon-checkbox-checked") : m("i.icon-checkbox-empty")))
                                                ])
                                            ];
                                        }))
                                    ])
                                ])
                            ] : m(".text-gray padding", i18n.t("no_sso_configurations"))
                        ] : m(".text-gray padding", i18n.t("unable_to_load_sso_configurations"))
                    ] : m(".text-gray padding", [i18n.t("loading"), m(".loading-dots")]))
                ]),
                m(".content-box", [
                    m("h3", i18n.t("api_keys")),
                    !loading ? [
                        settings ? [
                            m(".form", [
                                m(".form-section", [
                                    m(".form-label", i18n.t("primary_api_key")),
                                    m("textarea[readonly].form-input", {
                                        style: "resize: vertical; min-height: 150px; min-width: 30%;",
                                        placeholder: (!settings.apiKey ? i18n.t("not_yet_generated" ): ""),
                                        value: settings.apiKey
                                    })
                                ]),
                                m(".form-buttons", [
                                    Auth.validateRights(["org_edit"], m("button.with-icon", {onclick: confirmNewPrimaryKey}, [m("i.icon-reload"), i18n.t("new_api_key")]))
                                ])
                            ])
                        ] : m(".text-gray padding", i18n.t("unable_to_load_settings"))
                    ] : m(".text-gray padding", [i18n.t("loading"), m(".loading-dots")])
                ]),
            ] : ""
        ];
    }
};

export default Component_Organisation_Settings;
