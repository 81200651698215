﻿import m from 'mithril';
import Requester from '../../shared/request';
import Gateway from '../../shared/app_settings';
import Toasts from '../../shared/toasts';
import Auth from '../../shared/auth';
import ModalManager from '../../shared/modal';
import OrgFileService from '../../shared/storage/org_file_service';
import Modal_Organisation_New from './modal_organisation_new';
import i18n from "../../shared/i18n/i18n";
import RouterService from '../../shared/services/router.service';
import SessionController from "../../shared/session";

let loading = false;
let retry = false;
let orgs = [];
function reloadOrgs() {
    loading = true;
    setTimeout(function () { loadOrgs(); }, 500);
}

function loadOrgs() {
    loading = true;
    Requester.get(Gateway.admin + "account/organisations")
        .then(function (result) {
            orgs = result;
        })
        .catch(function (error) {
            if(!retry) {
                retry= true;
                reloadOrgs();
            } else {
                loading = false;
                if(!!error) {
                    Toasts.add("error", i18n.t("error"), i18n.t(error.response.properties.errorCode));
                }
            }
        })
        .then(function () {
            loading = false;
        });
}

function visitOrg(org) {
    loading = true;
    Requester.get(Gateway.identity + "req-tr?target=" + org.id)
        .then(function (result) {
            SessionController.clearSession();
            SessionController.getSession().then(function () {
                window.location = RouterService.getOrganisationTransitionUrl(org.hostname, result, RouterService.getAdminUrl());
            });
        })
        .catch(function (error) {
            Toasts.add("error", i18n.t("error"), i18n.t(error.response.properties.errorCode));
        })
        .then(function () {
            loading = false;
        });
}

function createOrg() {
    ModalManager.open(i18n.t("create_organisation"), [], Modal_Organisation_New, { callback: reloadOrgs });
}

const Page_Organisations = {
    oninit: function () {
        loadOrgs();
        let currentPage = "organisations";
    },
    view: function () {
        return [
            m(".content-box", [
                m(".toolbar", {class: loading ? "disable-buttons" : ""}, [
                    m(".toolbar-section", [
                        m("h1", i18n.t("organisations"))
                    ]),
                    m(".toolbar-section", [
                        Auth.validateRights(["org_create"], m("button.with-icon btn-cta", {onclick: createOrg}, [m("i.icon-plus"), i18n.t("create_organisation")]))
                    ])
                ])
            ]),
            !loading ? [
                orgs ? [
                    orgs.length > 0 ? [
                        m(".org-box-list", orgs.map(function (org) {
                            return [
                                m(".org-box",
                                    m(".flex-column", [
                                        m(".img",
                                            org.logoName ? m("img", {src: OrgFileService.getLogoPath(org.id, org.logoName)}) : ""
                                        ),
                                        m(".padding flex-column flex-auto full-height", [
                                            m(".flex-auto full-height", [
                                                m(".text-bold", org.name)
                                            ]),
                                            m(".form-buttons", [
                                                Auth.validateRights(["org_view"], m("button.btn-text", {
                                                    onclick: function () {
                                                        RouterService.navigateToOrganisation(org.id);
                                                    }
                                                }, i18n.t("settings"))),
                                                Auth.validateRights(["org_view"], m("button", {
                                                    onclick: function () {
                                                        visitOrg(org);
                                                    }
                                                }, i18n.t("access")))
                                            ])
                                        ])
                                    ])
                                )
                            ];
                        }))
                    ] : m(".text-gray content-box", i18n.t("no_organisations"))
                ] : m(".text-gray content-box", i18n.t("unable_to_load_organisations"))
            ] : m(".text-gray content-box", [i18n.t("loading"), m(".loading-dots")])
        ];
    }
};

export default Page_Organisations;
